import React, { useState, useRef, useEffect } from 'react';
import "./HipHop.css";
import "./Redwood.css";
import "./Home.css";
import "./IC.css";
import Hire from "./Hire.js";
import ProjectsSlider from "./ProjectsSlider";
import Footer from "./Footer";
import { ArrowRightIcon } from "@heroicons/react/solid";
import Nav from "./Nav";

export default function Redwood() {

    const projectsRef = useRef(null);
    const hireRef = useRef(null);
    const [loading, setLoading] = useState(true);

    const [expandedImage, setExpandedImage] = useState(null);

    const expandImage = (src) => {
        setExpandedImage(src);
    };

    const closeImage = () => {
        setExpandedImage(null);
    };

    const scrollToHire = () => {
        console.log("Scrolling to Hire section");
        hireRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const scrollToProjects = () => {
        const projectsSection = document.getElementById('projects');
        projectsSection.scrollIntoView({ behavior: 'smooth' });
    };

    const [activePage, setActivePage] = useState("Introduction");

    const refIntroduction = useRef(null);
    const refResearch = useRef(null);
    const refIdeation = useRef(null);
    const refHighFidelity = useRef(null);
    const refConclusion = useRef(null);

    const handleNavigation = (ref, pageName) => {
        setActivePage(pageName);
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    const [isInView, setIsInView] = useState(false);
    const aboutRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const aboutElement = aboutRef.current;
            const windowHeight = window.innerHeight;
            if (aboutElement && !isInView && aboutElement.getBoundingClientRect().top <= windowHeight) {
                setIsInView(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isInView]);




    return (

        <div className="HipHop">
            <Nav />
            <div className="hiphop-banner-img">
                <img className="header-banner-img"
                    alt="hiphopbanner"
                    src="/redwood-banner.png"
                />
            </div>


            <div class="flex items-center justify-center h-screen poppins-medium">
                <div class="container">
                    <div class="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
                        <div class="text-center">
                            <p class="text-sm mt-5 font-medium" style={{ color: '#b71126' }}>Project Summary

                            </p>

                            <h2
                                class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                                Redwood<span style={{ color: '#b71126' }}> Montessori</span>
                            </h2>
                            <h3 class='text-xl md:text-3xl mt-10'>Brand Revamp</h3>
                            <p class="text-md md:text-xl mt-10">
                                <a style={{ color: '#b71126' }}
                                    class="inline-block rounded-full text-white px-2 py-1 hover:bg-red-50"
                                    href="https://www.redwoodpreschools.in/" target="_blank" style={{ color: '#b71126' }}>
                                    Redwood Montessori
                                    <i class="fas fa-link ml-1"></i>
                                </a>
                                is a Brand Revamp project where I designed their logo, selected brand colors, and developed the
                                website to establish a strong brand identity for their preschool and daycare services among the
                                community.
                            </p>
                        </div>

                        <div class="flex flex-wrap gap-1 md:gap-2 justify-center items-center mt-10">

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">Logo
                                Design
                            </span>

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">
                                User Interviews
                            </span>

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">
                                Competitor Analysis
                            </span>

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">
                                UX Writing
                            </span>

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">
                                Information Architecture
                            </span>

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">UX
                                Design
                            </span>

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">Hi-Fi
                                Mockups

                            </span>

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">Figma

                            </span>

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">
                                React
                            </span>

                            <span
                                class="inline-block bg-gray-600 text-white font-normal rounded text-sm mt-2 px-2 py-1 sm:px-2 sm:font-medium sm:py-1 break-words mt-0">
                                Usability Testing
                            </span>

                        </div>
                    </div>
                </div>
            </div>

            <section class="mt-24 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="lg:py-24">
                            <h2 class="text-red">Introduction
                                <h2 class="text-3xl font-bold sm:text-4xl text-black">More about
                                    Redwood Montessori</h2>
                            </h2>


                            <p class="mt-4 text-gray-800">
                                Redwood Preschool & Daycare, situated in Bangalore, India, has been a cornerstone in the
                                nurturing and development of young minds in the preschool and daycare sector for over eight
                                flourishing years.

                                The school owner is deeply committed to nurturing young minds. Her vision is to create a
                                supportive community focused on holistic child development.

                                I've had the privilege of revamping the school's brand and online presence by creating a
                                strong
                                brand identity, a well-structured information architecture, strategic web design, and
                                development.
                            </p>


                        </div>

                        <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-second lg:h-full">
                            <img alt="" src="/redwood-intro.png"
                                onClick={() => expandImage("/redwood-intro.png")} class="absolute inset-0 h-full w-full object-cover" />
                        </div>

                        <div class="lg:py-24">
                            <h2 class="text-3xl font-bold sm:text-4xl">The Problem</h2>

                            <p class="mt-4 text-gray-800">
                                Redwood Montessori is emerging as a prominent name, especially after facing tough times
                                during
                                COVID-19. Its reputation is really important for its success. To attract more parents and
                                make
                                them feel good about choosing Redwood, <b>we need to create a strong brand presence and a
                                    website
                                    that feels trustworthy and
                                    welcoming.</b>
                            </p>
                        </div>

                        <div class="lg:py-24">
                            <h2 class="text-3xl font-bold sm:text-4xl">Hypothesis</h2>

                            <p class="mt-4 text-gray-800">
                                Through user research and a simplified path to contact, design a website that exceeds user’s
                                needs and expectations, and showcases Redwood's dedication to excellence in education. <b>We
                                    will
                                    know this to be successful when website analytics show more traffic and parents
                                    inquiries
                                    increase.</b>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 poppins-medium">
                    <header class="text-center">
                        <h2 class="text-3xl font-bold text-gray-900 sm:text-4xl">The Product</h2>

                        <p class="mx-auto max-w-md mt-4 text-gray-800">
                            This is the finalized version of the website, achieved after an extensive process of iterative
                            research,
                            design, and development.
                        </p>
                    </header>

                    <ul class="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-1 align-center mx-auto"
                        style={{ height: '70vh', width: '70vw' }}>

                        <li>
                            <a href="#" class="group relative block">
                                <img src="/redwood-product.gif" onClick={() => expandImage("/redwood-product.gif")} alt=""
                                    class="w-full h-full object-contain transition duration-500 group-hover:opacity-90" />
                            </a>
                        </li>
                    </ul>


                </div>
            </section>

            <section class="bg-white mt-20 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 md:py-16 lg:px-8">
                    <div class="mx-auto max-w-3xl text-center">
                        <h2 class="text-3xl font-bold text-gray-900 sm:text-4xl">Outcomes</h2>

                        <p class="mt-4 text-gray-800">
                            A significant increase in the below measures were observed within couple months after the
                            completion
                            of the project.
                        </p>
                    </div>

                    <div class="mt-8 sm:mt-12">
                        <dl class="grid grid-cols-1 gap-4 sm:grid-cols-3">
                            <div class="flex flex-col rounded-lg bg-gray-50 px-4 py-8 text-center">
                                <dt class="order-last text-lg font-medium text-gray-800">Admission Rates</dt>

                                <dd class="text-4xl font-extrabold text-blue-600 md:text-5xl text-red">60%</dd>
                            </div>

                            <div class="flex flex-col rounded-lg bg-gray-50 px-4 py-8 text-center">
                                <dt class="order-last text-lg font-medium text-gray-800">Website Traffic</dt>

                                <dd class="text-4xl font-extrabold text-blue-600 md:text-5xl text-red">72%</dd>
                            </div>

                            <div class="flex flex-col rounded-lg bg-gray-50 px-4 py-8 text-center">
                                <dt class="order-last text-lg font-medium text-gray-800">Enquiries by WhatsApp</dt>

                                <dd class="text-4xl font-extrabold text-blue-600 md:text-5xl text-red">90%</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>

            <section>
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 poppins-medium">
                    <header class="text-center">
                        <h2 class="text-xl font-bold text-gray-900 sm:text-3xl">How I Got There</h2>


                    </header>

                    <ul class="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-1" style={{ height: '50vh' }}>

                        <li>
                            <a href="#" class="group relative block">
                                <img src="/redwood-process.png" onClick={() => expandImage("/redwood-process.png")} alt=""
                                    class="w-full h-full object-contain transition duration-500 group-hover:opacity-90" />
                            </a>
                        </li>
                    </ul>

                </div>
            </section>

            <section class="mt-24 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="lg:py-24">
                            <h2 class="text-red">Research
                                <h2 class="text-3xl font-bold sm:text-4xl text-black">Research Learning from 10 parents who've enrolled
                                    their
                                    children at Redwood Montessori</h2>
                            </h2>
                            <p class="mt-4 text-gray-800">

                                The insights were gathered from 10 participants who have previously enrolled their
                                children at
                                Redwood Montessori. During this exploratory research, I aimed to answer a few key
                                questions:

                                <b>
                                    <ul>
                                        <li>What factors do parents consider when determining if a childcare center is
                                            trustworthy?</li>
                                        <li>What criteria do parents use to select a childcare center for their children?
                                        </li>
                                        <li>How do parents prefer to communicate with childcare centers like Redwood
                                            Montessori?
                                        </li>
                                    </ul></b>
                            </p>


                        </div>

                        <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-second lg:h-full">
                            <img alt="" src="/redwood-research.png" onClick={() => expandImage("/redwood-research.png")} class="absolute inset-0 h-full w-full object-cover" />
                        </div>
                    </div>
                </div>
            </section>

            <section class="text-white mt-24 poppins-medium" style={{ backgroundColor: '#b71126' }}>
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div class="mx-auto max-w-lg text-center">
                        <h2 class="text-3xl font-bold sm:text-4xl">Insights</h2>

                        <p class="mt-4 font-normal">
                            Below are some of the interesting insights that we got after exploratory interviews with the
                            parents.
                        </p>
                    </div>

                    <div class="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                        <a class="block rounded-xl border border-gray-800 p-8 shadow-xl transition">


                            <h2 class="mt-4 text-xl font-bold text-white">Trust</h2>

                            <p class="mt-1 font-normal text-sm">
                                "I trust a childcare center when they clearly communicate their approach to early childhood
                                education and safety measures. Transparency about fees and payment schedules is also
                                important
                                to me, as it helps ensure a secure and reliable environment for my child."
                            </p>
                        </a>

                        <a class="block rounded-xl border border-gray-800 p-8 shadow-xl transition">


                            <h2 class="mt-4 text-xl font-bold text-white">Montessori Education</h2>

                            <p class="mt-1 font-normal text-sm">
                                "The Montessori educational method's emphasis on hands-on learning, independence, and
                                individualized instruction resonates with my values as a parent."
                            </p>
                        </a>

                        <a class="block rounded-xl border border-gray-800 p-8 shadow-xl transition">


                            <h2 class="mt-4 text-xl font-bold text-white">Easy Communication</h2>

                            <p class="mt-1 font-normal text-sm">
                                "Using WhatsApp to ask questions and get updates makes it easy for parents to talk with the
                                childcare center. It helps us communicate smoothly and quickly, which is really helpful."
                            </p>
                        </a>
                    </div>


                </div>
            </section>

            <section class="mt-24 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="lg:py-24">
                            <h2 class="text-3xl font-bold sm:text-4xl">User Personas</h2>
                            <p class="mt-4 font-normal text-gray-800">
                                Based on the insights gathered from the research learning with parents who've enrolled their
                                children at Redwood Montessori, we can create user personas to represent the typical parent
                                demographic. These user personas reflect the key insights gathered from the research and
                                highlight the diverse needs and preferences of parents when choosing a childcare center like
                                Redwood Montessori.
                            </p>
                        </div>
                        <div class="relative h-64 rounded-lg sm:h-80 lg:order-second lg:h-full">
                            <img alt="" src="/redwood-user-persona.png" onClick={() => expandImage("/redwood-user-persona.png")}
                                class="absolute inset-0 w-full h-full object-cover object-center"
                                style={{ objectFit: 'contain' }} />
                        </div>
                    </div>
                </div>
            </section>


            <section class="mt-24 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="lg:py-24">
                            <h2 class="font-normal text-red">Content Strategy
                                <h2 class="text-3xl text-black font-bold sm:text-4xl">The client provided insights into the school's
                                    mission, vision, and competitive offerings compared to other institutions in the vicinity.
                                </h2>
                            </h2>
                            <p class="mt-4 font-normal text-gray-800">
                                After conducting exploratory interviews, analyzing competitors, and identifying Redwood's
                                strengths, I developed a comprehensive content strategy document for the web interface. This
                                involved
                                crafting compelling messaging that resonates with our target audience while highlighting
                                Redwood's unique offerings. Additionally, recognizing the importance of visual identity, I
                                decided to create a new logo to further enhance brand recognition and cohesion.
                                Incorporating
                                user insights and best practices in UX writing, the content strategy aims to provide an
                                engaging
                                and informative experience for visitors, ultimately driving engagement and conversions.
                            </p>

                        </div>

                        <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                            <img alt="" src="/redwood-content-strategy.png" onClick={() => expandImage("/redwood-content-strategy.png")}
                                class="absolute inset-0 h-full w-full object-cover" />
                        </div>
                    </div>
                </div>
            </section>



            <section class="mt-12 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
                    <header class="text-center">
                        <h2 class="font-normal text-red">Design
                            <h2 class="text-xl font-bold text-gray-900 sm:text-3xl">I designed the brand logo, and mockups
                                simultaneously to explore style options efficiently while adhering to the client's budget
                                constraints.</h2>
                        </h2>
                        <p class="mx-auto mt-4 max-w-md font-normal text-gray-800">
                            I initially sketched several logo concepts and later translated them into digital designs.
                        </p>
                    </header>

                    <ul class="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
                        <li>
                            <a href="#" class="group relative block">
                                <img src="/Redwood-Logo-Sketch-1.jpeg"
                                    alt=""
                                    class="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90" />


                            </a>
                        </li>

                        <li>
                            <a href="#" class="group relative block">
                                <img src="/Redwood-Logo-Sketch.png"
                                    alt=""
                                    class="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90" />


                            </a>
                        </li>

                        <li class="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
                            <a href="#" class="group relative block">
                                <img src="/redwood-logo-digital.png"
                                    alt=""
                                    class="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90" />


                            </a>
                        </li>
                    </ul>
                </div>
            </section>



            <section class="mt-24 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="lg:py-24">
                            <h2 class="font-normal text-red">Ideation</h2>
                            <h2 class="text-3xl font-bold sm:text-4xl">I generated ideas through whiteboarding, and sketching.
                            </h2>
                            <p class="mt-4 font-normal text-gray-800">
                                Afterward, I began generating design solutions by developing information architecture and
                                creating low-fidelity mockups through sketching.
                            </p>
                        </div>

                        <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-second lg:h-full">
                            <div class="carousel">
                                <img alt="" src="/redwood-ideation-1.jpg" onClick={() => expandImage("/redwood-ideation-1.jpg")}
                                    class="h-full w-full object-cover absolute transition duration-1000" />
                                <img alt="" src="images/redwood-hifi-1.png"
                                    class="h-full w-full object-cover absolute transition duration-1000 opacity-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="mt-12 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
                    <header class="text-center">
                        <h2 class="text-xl font-bold text-gray-900 sm:text-3xl">Hi-Fidelity Mockups</h2>

                        <p class="mx-auto mt-4 max-w-md font-normal text-gray-800">
                            These concepts were then transformed into high-fidelity mockups.
                        </p>
                    </header>

                    <ul class="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
                        <li>
                            <a href="#" class="group relative block">
                                <img src="/redwood-hifi-1.png" onClick={() => expandImage("/redwood-hifi-1.png")} alt=""
                                    class="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90" />


                            </a>
                        </li>

                        <li>
                            <a href="#" class="group relative block">
                                <img src="/redwood-hifi-2.png" onClick={() => expandImage("/redwood-hifi-2.png")} alt=""
                                    class="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90" />


                            </a>
                        </li>

                        <li class="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
                            <a href="#" class="group relative block">
                                <img src="/redwood-hifi-3.png" onClick={() => expandImage("/redwood-hifi-3.png")} alt=""
                                    class="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90" />


                            </a>
                        </li>
                    </ul>
                </div>
            </section>

            <section class="mt-24 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="lg:py-24">
                            <h2 class="font-normal text-red">Iterations
                                <h2 class="text-3xl text-black font-bold sm:text-4xl">Based on the feedback received from the usability
                                    testing
                                    with 5 participants, the below key points were highlighted for improvement</h2>
                            </h2>
                            <p class="mt-4 text-gray-800">
                                <ol class="list-decimal font-normal">
                                    <li><strong> Clarity on Programs Offered:</strong> Provide detailed information about
                                        daycare
                                        and
                                        preschool programs to clarify differences.</li>
                                    <li><strong> Aesthetic Improvement:</strong> Revise color scheme for a more visually
                                        appealing
                                        website design.</li>
                                    <li><strong> Addressing White Spaces:</strong> Optimize layout to reduce excessive white
                                        spaces
                                        while maintaining readability.</li>
                                    <li><strong> Detailed Facility Information:</strong> Provide comprehensive details about
                                        school
                                        amenities and resources.</li>
                                    <li><strong> Section on Daily Routine:</strong> Include a dedicated section detailing a
                                        typical
                                        day at the school.</li>
                                </ol>
                            </p>

                        </div>

                        <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-second lg:h-full">
                            <img alt="" src="/redwood-iteration-1.png" onClick={() => expandImage("/redwood-iteration-1.png")}
                                class="absolute inset-0 h-full w-full object-cover" />
                        </div>
                    </div>
                </div>
            </section>

            <section class="mt-24 poppins-medium">
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="lg:py-24">
                            <h2 class="font-normal text-red">Development
                                <h2 class="text-3xl text-black font-bold sm:text-4xl">In response to a tight time constraint, I began
                                    development simultaneously</h2>
                            </h2>
                            <p class="mt-4 font-normal text-gray-800">
                                Despite the project's immediate requirements not mandating the use of React, I opted for it.
                                My rationale was to establish a foundation that could seamlessly accommodate future
                                expansions. This strategic decision ensures scalability, allowing for the incorporation of
                                additional functions and features if the client decides to enhance the website in the
                                future.
                            </p>
                        </div>

                        <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                            <img alt="" src="/redwood-development.png" onClick={() => expandImage("/redwood-development.png")}
                                class="absolute inset-0 h-full w-full object-cover" />
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-0 lg:py-10 poppins-medium">
                    <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:items-stretch">
                        <div class="grid place-content-center rounded bg-gray-100 p-6 sm:p-8"
                            style={{ backgroundColor: '#b71126' }}>
                            <div class="mx-auto max-w-md text-center lg:text-left">
                                <header>
                                    <h2 class="text-xl font-bold sm:text-3xl text-white">Visual Design</h2>

                                    <p class="mt-4 font-normal" style={{ color: '#f5f5f5' }}>
                                        The design system I created will help to accommodate future goals as the client
                                        has ambitious plans of expanding the school branches and much more!
                                    </p>
                                </header>

                            </div>
                        </div>

                        <div class="lg:col-span-2 lg:px-8 lg:py-8">
                            <ul class="grid grid-cols-2 gap-4">
                                <li>
                                    <a href="#" class="group block">
                                        <img src="/redwood-visual-identity-1.png" alt=""
                                            class="aspect-square w-full rounded object-cover" />


                                    </a>
                                </li>

                                <li>
                                    <a href="#" class="group block">
                                        <img src="/redwood-visual-identity-2.png" alt=""
                                            class="aspect-square w-full rounded object-cover" />


                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 poppins-medium">
                    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="lg:py-24">
                            <h2 class="text-3xl font-bold sm:text-4xl">Learnings</h2>

                            <p class="mt-4 text-gray-600">

                                The website development process for a childcare center prioritized user needs through extensive research, ensuring a deep understanding of parents' preferences. This user-centric approach guided the design and development phases, resulting in a website that exceeded user expectations. A cohesive visual identity, including a new logo and color scheme, was developed to enhance brand recognition and convey the school's values effectively. Iterative design, involving ideation and mockups, facilitated continuous improvement based on user feedback. Additionally, strategic decisions, such as adopting React for development, demonstrated forward-thinking, ensuring scalability and flexibility for future enhancements.
                            </p>


                        </div>

                        <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                            <img alt=""
                                src="/redwood-learnings.png"
                                class="absolute inset-0 h-full w-full object-cover" />
                        </div>


                    </div>
                </div>
            </section>

            <span class="relative flex justify-center poppins-medium">
                <div
                    class="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"
                ></div>

                <span class="relative z-10 bg-white px-6">Thank You</span>
            </span>





            {
                expandedImage && (
                    <div className="overlay" onClick={closeImage}>
                        <span className="close-btn" onClick={closeImage}>
                            &times;
                        </span>
                        <img src={expandedImage} id="expandedImage" alt="Expanded" />
                    </div>
                )
            }
            <div>
                <ProjectsSlider />
            </div>
            <div ref={hireRef}>
                <Hire />
            </div>
        </div >

    );
}