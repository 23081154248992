import React, { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Hire.css';

export default function Hire() {
    const form = useRef();
    const [isInView, setIsInView] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            const formElement = form.current;
            const windowHeight = window.innerHeight;
            if (formElement && !isInView && formElement.getBoundingClientRect().top <= windowHeight) {
                setIsInView(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isInView]);

    const sendEmail = async (e) => {
        e.preventDefault();

        try {
            await emailjs.sendForm('service_j21c8d3', 'template_q3r5ajg', form.current, 'JjzLgrLM3zmxCbU3G');
            console.log('Email sent successfully');
            setIsEmailSent(true);
            setTimeout(() => setIsEmailSent(false), 5000); // Hide the notification after 5 seconds
        } catch (error) {
            console.error('Error sending email:', error);
        }

        // Clear form data
        form.current.reset();
    };

    return (
        <div className={`bg-gray-100 ${isInView ? 'fade-in' : ''}`}>
            <div className="flex justify-center poppins-medium">
                <div className="max-w-screen-xl mx-auto px-6 md:px-20 py-12 sm:py-16 lg:py-24">
                    <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                        <div className="lg:col-span-2 lg:py-12">
                            <h2 className="mx-auto max-w-4xl font-display text-5xl font-bold tracking-normal text-slate-900 sm:text-7xl">Hire
                                <span className="relative whitespace-nowrap text-orange-400">
                                    <svg aria-hidden="true" viewBox="0 0 418 42" className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path></svg>
                                    <span className="relative fill-orange-300-70"> Me</span>
                                </span>
                            </h2>
                            <p className="mt-8 max-w-xl text-md text-gray-700">
                                I'm actively seeking new opportunities. I combine creativity and technical skills to create engaging experiences that blend design and development seamlessly. I focus on understanding what users need and create digital solutions that are captivating, work well, and adapt with precision. Let's unleash the potential of outstanding user experiences.
                            </p>
                            <div className="mt-8">
                                <button aria-label="Opening Gmail to send an email to shyamravi.ux@gmail.com"><a href="mailto:shyamravi.ux@gmail.com" className="text-2xl font-bold text-white">
                                    <mark className="px-4 py-4 text-white bg-shyam rounded shadow-lg">
                                        shyamravi.ux@gmail.com
                                    </mark>
                                </a></button>
                            </div>
                        </div>
                        <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                            <form ref={form} onSubmit={sendEmail} action="" className="space-y-4">
                                <div>
                                    <label htmlFor="name">Name</label>
                                    <input
                                        id="name"
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"

                                        type="text"
                                        name="user_name"
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                    <div>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            id="email"
                                            className="w-full rounded-lg border border-gray-200 p-3 text-sm"

                                            type="email"
                                            name="user_email"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"

                                        rows="8"
                                        name="message"
                                    ></textarea>
                                </div>
                                <div className="mt-4">
                                    <button
                                        type="submit"
                                        value="Send"
                                        className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto shadow-lg"
                                    >
                                        Send Enquiry
                                    </button>
                                </div>
                                {isEmailSent && (
                                    <div className="notification">
                                        Email has been sent successfully!
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
