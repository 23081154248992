import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { XIcon } from "@heroicons/react/solid";
import "./Nav.css";

const Nav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    };

    const scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Check if the current location is the About or Projects page
    const isAboutPage = location.pathname === '/about';
    const isProjectsPage = location.pathname === '/projects';

    return (
        <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 shadow-lg">
            <div className="max-w-screen-xl mx-auto px-6 md:px-20 py-4">
                <div className="flex justify-between items-center">
                    <Link onClick={scrollToTop} to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src="/my-logo.svg" className="h-8" alt="Shyam Portfolio Logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
                    </Link>
                    <div className="md:flex md:items-center hidden poppins-medium">
                        <ul className="flex flex-row space-x-6">
                            <li onClick={scrollToTop}>
                                <Link to="/about" className={`text-black hover:text-blue-900 ${isAboutPage ? 'underline-blue' : ''}`}>About</Link>
                            </li>
                            <li onClick={scrollToTop}>
                                <Link to="/projects" className={`text-black hover:text-blue-900 ${isProjectsPage ? 'underline-blue' : ''}`}>Projects</Link>
                            </li>
                            <li>
                                <a href="https://drive.google.com/file/d/1kXEJIRX_PilFpnSRkLiv4QiRk65Ke70M/view?usp=sharing" aria-label="Resume, opens in a new window" target="_blank" className="text-black hover:text-blue-900">Resume</a>
                            </li>
                        </ul>
                    </div>
                    <div className="md:hidden flex items-center">
                        <button onClick={toggleMenu} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                            <span className="sr-only">Toggle menu</span>
                            {isMenuOpen ? (
                                <XIcon className="w-5 h-5" aria-hidden="true" />
                            ) : (
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            {isMenuOpen && (
                <div className="md:hidden w-full" id="navbar-sticky poppins-medium">
                    <ul className="flex flex-col p-4 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 space-y-4">
                        <li onClick={scrollToTop}>
                            <Link to="/about" className="text-black hover:text-blue-700">About</Link>
                        </li>
                        <li onClick={scrollToTop}>
                            <Link to="/projects" className="text-black hover:text-blue-700">Projects</Link>
                        </li>
                        <li>
                            <a href="https://drive.google.com/file/d/1kXEJIRX_PilFpnSRkLiv4QiRk65Ke70M/view?usp=sharing" className="text-black hover:text-blue-700" aria-label="Resume, opens in a new window" target="_blank">Resume</a>

                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
}

export default Nav;
