import React, { useRef, useEffect, useState } from "react";
import "./About.css";
import { ArrowRightIcon } from "@heroicons/react/solid";
import "./Home.css";
import WhyMe from "./WhyMe";
import Hire from "./Hire";
import Nav from "./Nav";

export default function About() {
    const aboutRef = useRef(null);
    const hireRef = useRef(null);
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const aboutElement = aboutRef.current;
            const windowHeight = window.innerHeight;
            if (aboutElement && !isInView && aboutElement.getBoundingClientRect().top <= windowHeight) {
                setIsInView(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isInView]);

    return (
        <section aria-label="This section gives a brief about my me">
            <div className="about">
                <Nav />

                <div ref={aboutRef} className={`max-w-screen-xl mx-auto px-6 md:px-20 pt-12 mt-12 ${isInView ? 'fade-in' : ''}`}>
                    <div className="flex justify-center items-center bg-hero h-screen overflow-hidden poppins-medium">
                        <div className="flex flex-col gap-6 md:flex-row items-center max-w-8xl">
                            <div className="w-full md:w-1/2 lg:pr-32">
                                <h1 className="mx-auto max-w-4xl font-display text-5xl font-bold tracking-normal text-slate-900 sm:text-7xl">About
                                    <span className="relative whitespace-nowrap text-orange-400">
                                        <svg aria-hidden="true" viewBox="0 0 418 42" className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path></svg>
                                        <span className="relative fill-orange-300-70"> Me</span>
                                    </span>
                                </h1>
                                <h3 className="mt-6 md:mt-10 text-md lg:text-md text-left md:text-left text-gray-900 font-normal tracking-wider leading-relaxed">
                                    My passion for Human-Centered Computing began at CenturyLink Technologies in 2020. After completing my Master's from 2021 to 2023, I dove into UX research. Now, I'm eager to kickstart my career as a full-time UX Designer. Beyond work, I'm a sports enthusiast and avid traveler. Adaptable and curious, I'm always ready to learn and explore.
                                </h3>
                            </div>
                            <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                                <img src="about-img-4.jpg" alt="Shyam's picture standing in front of Old Trafford stadium" />
                            </div>
                        </div>
                    </div>
                </div>



                <div ref={hireRef}>
                    <Hire />
                </div>
            </div>
        </section>
    );
}
