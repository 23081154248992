import React, { useRef, useEffect, useState } from "react";
import Nav from "./Nav.js";
import "./Home.css";

export default function Projects() {
    const rdRef = useRef(null);
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const rdElement = rdRef.current;
            if (rdElement && !isInView && rdElement.getBoundingClientRect().top <= windowHeight) {
                setIsInView(true);
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isInView]);

    return (
        <div className="px-4 sm:px-0">
            <Nav />

            <section aria-label="display shyam's work or projects related to User Experience Research and User Experience Design">
                <div className="heading mt-20 mb-20 poppins-medium">
                    <p className="recent-projects text-sm leading-6 sm:text-base sm:leading-10">Engage with the stories behind my captivating case studies</p>
                    <h2 ref={rdRef} className={`mx-auto max-w-4xl font-display text-5xl font-bold tracking-normal text-slate-900 sm:text-7xl mt-2 ${isInView ? 'fade-in' : ''}`}>
                        Featured
                        <span className="relative whitespace-nowrap text-orange-400">
                            <svg
                                aria-hidden="true"
                                viewBox="0 0 418 42"
                                className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70"
                                preserveAspectRatio="none"
                            >
                                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                            </svg>
                            <span className="relative fill-orange-300-70"> Projects</span>
                        </span>
                    </h2>

                    <div className={`px-2 py-20 w-full flex justify-center ${isInView ? 'fade-in' : ''}`}>
                        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
                            <div className="lg:w-1/2 lg:scale-110 h-80 bg-cover lg:h-full rounded-t-none border lg:rounded-lg" role="img" aria-label="Redwood project banner image"
                                style={{ backgroundImage: 'url("./rd.png")' }}>
                            </div>
                            <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg text-sm text-left">
                                <h3 className="text-2xl text-gray-800 font-bold">
                                    Redwood Montessori
                                </h3>
                                <p className="mt-4 text-lg text-gray-700">
                                    A freelance project revamping Redwood Preschool & Daycare's brand and website, reflecting their 8 years of commitment to young learners.
                                </p>
                                <div className="mt-8">
                                    <button><a href="/projects/redwood" className="bg-gray-900 text-gray-100 px-3 py-2 font-semibold rounded">Read Story</a></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`px-2 py-20 w-full flex justify-center ${isInView ? 'fade-in' : ''}`}>
                        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
                            <div className="lg:w-1/2 lg:scale-110 h-80 bg-cover lg:h-full rounded-t-none border lg:rounded-lg" role="img" aria-label="Costco project banner image"
                                style={{ backgroundImage: 'url("./costco-header-banner.png")' }}>
                            </div>
                            <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg text-sm text-left">
                                <h3 className="text-2xl text-gray-800 font-bold">
                                    Costco Mobile App Redesign
                                </h3>
                                <p className="mt-4 text-lg text-gray-700">
                                    Improving UX design within the Costco mobile app, addressing challenges in navigation, product discovery, and overall user satisfaction.
                                </p>
                                <div className="mt-8">
                                    <button> <a href="/projects/costco" className="bg-gray-900 text-gray-100 px-3 py-2 font-semibold rounded">Read Story</a></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`px-2 py-20 w-full flex justify-center ${isInView ? 'fade-in' : ''}`}>
                        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
                            <div className="lg:w-1/2 lg:scale-110 h-80 bg-cover lg:h-full rounded-t-none border lg:rounded-lg" role="img" aria-label="HipHop project banner image"
                                style={{ backgroundImage: 'url("./hiphopframe.png")' }}>
                            </div>
                            <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg text-sm text-left">
                                <h3 className="text-2xl text-gray-800 font-bold">
                                    HipHop Fish & Chicken
                                </h3>
                                <p className="mt-4 text-lg text-gray-700">
                                    Redesigned the logo and navigation menu pages for HipHop Fish & Chicken, a fast-food restaurant serving customers primarily across Maryland, USA.
                                </p>
                                <div className="mt-8">
                                    <button><a href="/projects/hiphop" className="bg-gray-900 text-gray-100 px-3 py-2 font-semibold rounded">Read Story</a></button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
                {/* <div>
                <ProjectsSlider />
            </div> */}
            </section>
        </div>
    );
}
