import React, { useEffect, useRef, useState } from "react";
import "./Projects.css";

export default function WhyMe() {
    const featuresRef = useRef(null);
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const featuresElement = featuresRef.current;
            const windowHeight = window.innerHeight;
            if (featuresElement && !isInView && featuresElement.getBoundingClientRect().top <= windowHeight) {
                setIsInView(true);
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isInView]);

    return (
        <section className={`nav-style bg-white why-choose-me-margin mb-20 poppins-medium ${isInView ? 'fade-in' : ''}`}>
            <div className="container mx-auto px-6 md:px-20">
                <div id="features" ref={featuresRef} className="py-4 md:py-12 lg:py-16 mt-20">
                    <div className="text-center mt-20">
                        <p className="recent-projects text-sm leading-6 sm:text-base sm:leading-10"> Choose a UX Engineer who transforms complexity into simplicity by crafting seamless digital user journeys</p>
                        <h2 className="mx-auto max-w-4xl font-display text-5xl font-bold tracking-normal text-slate-900 sm:text-7xl mt-2">Why
                            <span className="relative whitespace-nowrap text-orange-400">
                                <svg aria-hidden="true" viewBox="0 0 418 42" className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none">
                                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                                </svg>
                                <span className="relative fill-orange-300-70"> Choose Me</span>
                            </span>
                        </h2>
                    </div>

                    <ul className="mt-6 md:mt-16 grid grid-cols-1 gap-6 text-center text-slate-700 md:grid-cols-2">
                        <li className="bg-white px-6 py-8">
                            <img src="/why-me-passion.svg" alt="heart icon" className="mx-auto h-10 w-10" />
                            <h3 className="my-3 font-medium mt-8">Passionate about UX design and UI development</h3>
                        </li>
                        <li className="bg-white px-6 py-8">
                            <img src="/why-me-work.svg" alt="bag icon" className="mx-auto h-10 w-10" />
                            <h3 className="my-3 font-medium mt-8">2 years of industry experience</h3>
                        </li>
                        <li className="bg-white px-6 py-8 mt-8">
                            <img src="/why-me-academic.svg" alt="mortarboard hat icon" className="mx-auto h-10 w-10" />
                            <h3 className="my-3 font-display font-medium mt-8">Strong academic background with a master's in HCI</h3>
                        </li>
                        <li className="bg-white px-6 py-8 mt-8">
                            <img src="/why-me-authorized.svg" alt="authorization icon" className="mx-auto h-10 w-10" />
                            <h3 className="my-3 font-display font-medium group-hover:text-primary-500 mt-8">Authorized to work for any U.S based employer</h3>
                        </li>
                        <li className="bg-white px-6 py-8 mt-8">
                            <img src="/why-me-relocate.svg" alt="map pin icon" className="mx-auto h-10 w-10" />
                            <h3 className="my-3 font-display font-medium group-hover:text-primary-500 mt-8">Flexible to relocate & available for immediate employment</h3>
                        </li>
                        <li className="bg-white px-6 py-8 mt-8">
                            <img src="/why-me-salary.svg" alt="dollar icon" className="mx-auto h-10 w-10" />
                            <h3 className="my-3 font-display font-medium group-hover:text-primary-500 mt-8">Open to negotiating salary & benefits</h3>
                        </li>
                    </ul>

                </div>
            </div>
        </section>
    );
}
