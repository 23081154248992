import React, { useState, useRef, useEffect } from 'react';
import "./Azure.css";


export default function Azure() {

    const [activePage, setActivePage] = useState("Introduction");

    const refIntroduction = useRef(null);
    const refResearch = useRef(null);
    const refConclusion = useRef(null);

    const handleNavigation = (ref, pageName) => {
        setActivePage(pageName);
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const handleScroll = () => {
            const introSection = refIntroduction.current.getBoundingClientRect();
            const isInView = introSection.top >= 0 && introSection.bottom <= window.innerHeight;
            if (isInView) {
                setActivePage("Introduction");
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <div className="azure">

            <div className="hiphop-banner-img">
                <img className="header-banner-img"
                    alt="hiphopbanner"
                    src="/azure.gif"
                />
            </div>

            <div className="column-1">
                <h1 className="project-name">
                    Azure Active Directory Automation Bot
                </h1>
                <h2 className="project-type" >
                    Automation Bot Development
                </h2>
            </div>

            <div className="hiphop_header">
                <div className="column-2">
                    <p className="role">
                        <h3 className="section-heading">Job Roles</h3>
                        <p>
                            <span className="tool">UI Developer</span>
                            <span className="tool">Power Automate Developer</span>
                        </p>
                    </p>
                </div>
                {/* <div className="column-5">
                    <p className="project-duration">
                        <h3 className="section-heading">Project Duration</h3>
                        <p>
                            <span className="tool">6 Months</span>
                        </p>
                    </p>
                </div> */}
                <div className="column-3">
                    <p className="keywords">
                        <h3 className="section-heading">Keywords</h3>
                        <p>
                            <span className="tool">Chatbot Development</span>
                            <span className="tool">Azure Active Directory</span>
                            <span className="tool">Automation</span>
                        </p>
                    </p>
                </div>
                <div className="column-4">
                    <p className="tools-used">
                        <h3 className="section-heading">Tools & Technologies used</h3>
                        <p>
                            <span className="tool">LUIS</span>
                            <span className="tool">Power Automate</span>
                            <span className="tool">Node.js</span>
                        </p>
                    </p>
                </div>

            </div>

            <div class="breadcrumbs">
                <h3 className="process">Design Process</h3>
                <ul class="azure-steps">
                    <li class="azure-step" onClick={() => handleNavigation(refIntroduction, "Introduction")}>
                        <a>
                            <i aria-hidden="true"></i> Discovery
                        </a>
                    </li>
                    <li class="azure-step" onClick={() => handleNavigation(refResearch)}>
                        <a>
                            <i aria-hidden="true"></i> Implementation
                        </a>
                    </li>
                    <li class="azure-step" onClick={() => handleNavigation(refConclusion)}>
                        <a>
                            <i aria-hidden="true"></i> Conclusion
                        </a>
                    </li>
                </ul>
            </div>

            <div ref={refIntroduction}>
                <img className="azure_project" src="/azure_intro.svg"></img>
            </div>
            <div ref={refResearch}>
                <img className="azure_project" src="/azure_implementation.svg"></img>
            </div>
            <div ref={refConclusion}>
                <img className="azure_project" src="/azure_conclusion (2).svg"></img>
            </div>

        </div>


    );
}