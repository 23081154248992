import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import "./Redwood.css";
import "./Margins.css";
import "./Projects.css";

function ProjectsSlider() {
    const location = useLocation();

    useEffect(() => {
        const swiper = new Swiper('.swiper-container', {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 32,
            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                640: {
                    centeredSlides: true,
                    slidesPerView: 1.25,
                },
                1024: {
                    centeredSlides: false,
                    slidesPerView: 1.5,
                },
            },
            navigation: {
                nextEl: '.next-button',
                prevEl: '.prev-button',
            },
        });

        return () => {
            swiper.destroy();
        };
    }, []);

    return (
        <section id="testimonials" className="montserrat-text-500 text-xs bg-white md:text-base mb-10 poppins-medium">
            <div className="mx-auto md:max-w-screen-xl lg:max-w-screen-2xl px-4 py-16 sm:px-6 sm:py-24 lg:me-0 lg:pe-8 lg:ps-8 mb-0">
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:items-center lg:gap-x-16 mb-0">
                    <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
                        <h1 className="mx-auto max-w-4xl font-display text-5xl font-bold tracking-normal text-slate-900 sm:text-7xl">More
                            <span className="relative whitespace-nowrap text-orange-400">
                                <svg aria-hidden="true" viewBox="0 0 418 42" className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path></svg>
                                <span className="relative fill-orange-300-70"> Projects</span>
                            </span>
                        </h1>

                        <p className="mt-8 text-black text-sm">
                            Unlock the secrets of my other compelling project case studies
                        </p>

                        <div className="hidden lg:mt-8 lg:flex lg:gap-4">
                            <button
                                className="prev-button rounded-full border border-gray-900 p-3 text-gray-900 hover:bg-gray-900 hover:text-gray-50"
                            >
                                <span className="sr-only">Previous Slide</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-5 w-5 rtl:rotate-180"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15.75 19.5L8.25 12l7.5-7.5"
                                    />
                                </svg>
                            </button>

                            <button
                                className="next-button rounded-full  border border-gray-900 p-3 text-gray-900 hover:bg-gray-900 hover:text-gray-50"
                            >
                                <span className="sr-only">Next Slide</span>
                                <svg
                                    className="h-5 w-5 rtl:rotate-180"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9 5l7 7-7 7"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="-mx-6 lg:col-span-2 lg:mx-0">
                        <div className="swiper-container !overflow-hidden">
                            <div className="swiper-wrapper">
                                {location.pathname === '/projects/redwood' && (
                                    <>
                                        <div className="swiper-slide">
                                            <blockquote
                                                className="flex h-full flex-col justify-between p-12"
                                            >
                                                <div>
                                                    <a className="p-8 max-w-lg border-indigo-300 rounded-2xl hover:shadow-xl hover:shadow-indigo-50 flex flex-col items-center"
                                                        href="/projects/costco">
                                                        <img src="/costco-header-banner.png" alt="hiphop banner img" className="shadow rounded-lg overflow-hidden border" />
                                                        <div className="mt-8">
                                                            <h2 className="font-bold text-black">Costco</h2>
                                                            <p className="mt-2 text-black">Reimagining the branding of HipHop Fish & Chicken through a logo design and improving website user experiences.
                                                            </p>
                                                            <div className="mt-5">
                                                                <button type="button" className="inline-flex items-center rounded-md border border-transparent bg-gray-900 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700">
                                                                    Read Story
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </blockquote>
                                        </div>
                                        <div className="swiper-slide">
                                            <blockquote
                                                className="flex h-full flex-col justify-between p-12"
                                            >
                                                <div>
                                                    <a className="p-8 max-w-lg border-indigo-300 rounded-2xl hover:shadow-xl hover:shadow-indigo-50 flex flex-col items-center"
                                                        href="/projects/hiphop">
                                                        <img src="/hiphopframe.png" alt="hiphop banner img" className="shadow rounded-lg overflow-hidden border" />
                                                        <div className="mt-8">
                                                            <h2 className="font-bold text-black">HipHop Fish & Chicken</h2>
                                                            <p className="mt-2 text-black">Reimagining the branding of HipHop Fish & Chicken through a logo design and improving website user experiences.
                                                            </p>
                                                            <div className="mt-5">
                                                                <button type="button" className="inline-flex items-center rounded-md border border-transparent bg-gray-900 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700">
                                                                    Read Story
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </>
                                )}

                                {location.pathname === '/projects/costco' && (
                                    <>
                                        <div className="swiper-slide">
                                            <blockquote
                                                className="flex h-full flex-col justify-between p-12"
                                            >
                                                <div>
                                                    <a className="p-8 max-w-lg border-indigo-300 rounded-2xl hover:shadow-xl hover:shadow-indigo-50 flex flex-col items-center"
                                                        href="/projects/internationalsConnect">
                                                        <img src="/rd.png" alt="Housync banner image" className="shadow rounded-lg overflow-hidden border" />
                                                        <div className="mt-8">
                                                            <h2 className="font-bold text-black">Redwood</h2>
                                                            <p className="mt-2 text-black"> A web application that helps people moving to foreign countries find accommodations, roommates, and communities.
                                                            </p>
                                                            <div className="mt-5">
                                                                <button type="button" className="inline-flex items-center rounded-md border border-transparent bg-gray-900 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700">Read Story</button>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </blockquote>
                                        </div>
                                        <div className="swiper-slide">
                                            <blockquote
                                                className="flex h-full flex-col justify-between p-12"
                                            >
                                                <div>
                                                    <a className="p-8 max-w-lg border-indigo-300 rounded-2xl hover:shadow-xl hover:shadow-indigo-50 flex flex-col items-center"
                                                        href="/projects/hiphop">
                                                        <img src="/hiphopframe.png" alt="hiphop banner img" className="shadow rounded-lg overflow-hidden border" />
                                                        <div className="mt-8">
                                                            <h2 className="font-bold text-black">HipHop Fish & Chicken</h2>
                                                            <p className="mt-2 text-black">Reimagining the branding of HipHop Fish & Chicken through a logo design and improving website user experiences.
                                                            </p>
                                                            <div className="mt-5">
                                                                <button type="button" className="inline-flex items-center rounded-md border border-transparent bg-gray-900 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700">
                                                                    Read Story
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </>
                                )}

                                {location.pathname === '/projects/hiphop' && (
                                    <>
                                        <div className="swiper-slide">
                                            <blockquote
                                                className="flex h-full flex-col justify-between p-12"
                                            >
                                                <div>
                                                    <a className="p-8 max-w-lg border-indigo-300 rounded-2xl hover:shadow-xl hover:shadow-indigo-50 flex flex-col items-center"
                                                        href="/projects/costco">
                                                        <img src="/costco-header-banner.png" alt="hiphop banner img" className="shadow rounded-lg overflow-hidden border" />
                                                        <div className="mt-8">
                                                            <h2 className="font-bold text-black">Costco</h2>
                                                            <p className="mt-2 text-black">Reimagining the branding of HipHop Fish & Chicken through a logo design and improving website user experiences.
                                                            </p>
                                                            <div className="mt-5">
                                                                <button type="button" className="inline-flex items-center rounded-md border border-transparent bg-gray-900 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700">
                                                                    Read Story
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </blockquote>
                                        </div>
                                        <div className="swiper-slide">
                                            <blockquote
                                                className="flex h-full flex-col justify-between p-12"
                                            >
                                                <div>
                                                    <a className="p-8 max-w-lg border-indigo-300 rounded-2xl hover:shadow-xl hover:shadow-indigo-50 flex flex-col items-center"
                                                        href="/projects/redwood">
                                                        <img src="/rd.png" alt="Housync banner image" className="shadow rounded-lg overflow-hidden border" />
                                                        <div className="mt-8">
                                                            <h2 className="font-bold text-black">Redwood</h2>
                                                            <p className="mt-2 text-black"> A web application that helps people moving to foreign countries find accommodations, roommates, and communities.
                                                            </p>
                                                            <div className="mt-5">
                                                                <button type="button" className="inline-flex items-center rounded-md border border-transparent bg-gray-900 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700">Read Story</button>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 flex justify-center gap-4 lg:hidden">
                    <button
                        aria-label="Previous slide"
                        className="prev-button rounded-full border border-pink-600 p-4 text-pink-600 hover:bg-pink-600 hover:text-white"
                    >
                        <svg
                            className="h-5 w-5 -rotate-180 transform"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15.75 19.5L8.25 12l7.5-7.5"
                            />
                        </svg>
                    </button>
                    <button
                        aria-label="Next slide"
                        className="next-button rounded-full border border-pink-600 p-4 text-pink-600 hover:bg-pink-600 hover:text-white"
                    >
                        <svg
                            className="h-5 w-5 transform"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </section>
    );
}

export default ProjectsSlider;
