import React from "react";
import "./Footer.css";

export default function Footer() {
    return (
        <section id="hire" className="relative poppins-medium">
            {/* <footer className="footer">
                <div className="footer-content">

                    <div className="left-align">
                        <div className="copyright">
                            © 2023 Shyam Ravichandran. All rights reserved.
                        </div>
                    </div>

                    <div className="center-align">
                        <div className="footer-text">
                            <span className="footer-text-title">Made with</span> <span className="heart">&hearts;</span> <span className="footer-text-title">for React</span>
                        </div>
                    </div>


                    <div className="footer-icons">
                        <a href="https://www.linkedin.com/in/shyam-ravichandran/" target="_blank" rel="noopener noreferrer">
                            <img className="linkedIn_icon" src="/linkedin.png" alt="LinkedIn" />
                        </a>
                        <a href="mailto:shyamravi.ux@gmail.com">
                            <img className="gmail_icon" src="/gmail (1).png" alt="Gmail" />
                        </a>
                    </div>



                </div>
            </footer> */}

            <footer className="bg-gray-950">
                <div className="mx-auto max-w-5xl px-4 py-16 sm:px-6 lg:px-8">
                    <div className="flex justify-center text-teal-600">
                        <a href="#" role="button" class="flex items-center justify-center text-2xl font-semibold text-gray-900 dark:text-white">
                            <img src="/sr-logo-white.svg" class="h-12 mr-3 sm:h-9" alt="shyam portfolio logo" />

                        </a>
                    </div>

                    <p className="mx-auto mt-6 max-w-lg text-center leading-relaxed text-gray-100">
                        Copyright © Shyam Ravichandran 2024. All rights reserved.
                    </p>

                    {/* <ul className="mt-12 flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-12">
                        <li>
                            <a className="text-gray-100 transition hover:text-gray-700/75" href="#"> About </a>
                        </li>

                        <li>
                            <a className="text-gray-100 transition hover:text-gray-700/75" href="#"> Project </a>
                        </li>

                        <li>
                            <a className="text-gray-100 transition hover:text-gray-700/75" href="#"> Resume </a>
                        </li>


                    </ul> */}

                    <ul className="mt-12 flex justify-center gap-6 md:gap-8">


                        <li>
                            <button aria-label="Opening Shyam's LinkedIn Profile"><a
                                href="https://www.linkedin.com/in/shyam-ravichandran/"
                                rel="noreferrer"
                                target="_blank"
                                className="text-gray-700 transition hover:text-gray-700/75"
                            >
                                <span className="sr-only">LinkedIn</span>

                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                                    <path fill="#000000" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                                </svg>

                            </a></button>
                        </li>








                    </ul>
                </div>
            </footer>
        </section >
    );
}
