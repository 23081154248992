import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './Home'
import About from './About'
import Projects from './Projects'
import Skills from './Skills'
import Azure from './Azure'
import HipHop from './HipHop'
import Costco from './Costco'
import IC from './IC'
import Footer from './Footer'
import Redwood from './Redwood'
import Facilities from './Facilities'
import Hire from './Hire'

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/skills" element={<Skills />} />
                <Route path="/footer" element={<Footer />} />
                <Route path="/projects/azure" element={<Azure />} />
                <Route path="/projects/hiphop" element={<HipHop />} />
                <Route path="/projects/costco" element={<Costco />} />
                <Route path="/projects/internationalsconnect" element={<IC />} />
                <Route path="/projects/redwood" element={<Redwood />} />
                <Route path="/facilities" element={<Facilities />} />
                <Route path="/hire" element={<Hire />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter