import React, { useState, useRef, useEffect } from 'react';
import "./HipHop.css";
import ProjectsSlider from './ProjectsSlider';
import Nav from "./Nav";

export default function HipHop() {

    const [expandedImage, setExpandedImage] = useState(null);

    const expandImage = (src) => {
        setExpandedImage(src);
    };

    const closeImage = () => {
        setExpandedImage(null);
    };

    const [activePage, setActivePage] = useState("Introduction");

    const refIntroduction = useRef(null);
    const refResearch = useRef(null);
    const refIdeation = useRef(null);
    const refHighFidelity = useRef(null);
    const refConclusion = useRef(null);

    const handleNavigation = (ref, pageName) => {
        setActivePage(pageName);
        ref.current.scrollIntoView({ behavior: "smooth" });
    };



    const [isInView, setIsInView] = useState(false);
    const aboutRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const aboutElement = aboutRef.current;
            const windowHeight = window.innerHeight;
            if (aboutElement && !isInView && aboutElement.getBoundingClientRect().top <= windowHeight) {
                setIsInView(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isInView]);

    return (


        <div className="HipHop">
            <Nav />
            <div className="hiphop-banner-img">
                <img className="header-banner-img"
                    alt="hiphopbanner"
                    src="/hiphopframe.png"
                />
            </div>

            <div className="column-1 poppins-medium">
                <h1 className="project-name">
                    HipHop Fish & Chicken
                </h1>
                <h2 className="project-type" >
                    UX Case Study
                </h2>
            </div>

            <div className="hiphop_header poppins-medium">
                <div className="column-2">
                    <h3 className="section-heading">Job Roles</h3>
                    <p>
                        <span className="tool">UX Researcher</span>
                        <span className="tool">Visual Designer</span>
                    </p>
                </div>
                {/* <div className="column-5">
                    <h3 className="section-heading">Project Duration</h3>
                    <p>
                        <span className="tool">4 Months</span>
                    </p>
                </div> */}
                <div className="column-3">
                    <h3 className="section-heading">Keyword</h3>
                    <p>
                        <span className="tool">Branding</span>
                        <span className="tool">Logo Design</span>
                        <span className="tool">Visual Design</span>
                    </p>
                </div>
                <div className="column-4">
                    <h3 className="section-heading">Tools & Technologies used</h3>
                    <p>
                        <span className="tool">Figma</span>
                        <span className="tool">Adobe Illustrator</span>
                    </p>
                </div>
            </div>

            <div class="breadcrumbs poppins-medium">
                <h3 className="process">Design Process</h3>
                <ul class="steps">
                    <li class={`step ${activePage === "Introduction" ? "active" : ""}`} onClick={() => handleNavigation(refIntroduction, "Introduction")}>
                        <a>
                            <i aria-hidden="true"></i> Discovery
                        </a>
                    </li>
                    <li class="step" onClick={() => handleNavigation(refResearch)}>
                        <a>
                            <i aria-hidden="true"></i> Research
                        </a>
                    </li>
                    <li class="step" onClick={() => handleNavigation(refHighFidelity)}>
                        <a>
                            <i aria-hidden="true"></i> High-Fidelity
                        </a>
                    </li>
                    <li class="step" onClick={() => handleNavigation(refConclusion)}>
                        <a>
                            <i aria-hidden="true"></i> Conclusion
                        </a>
                    </li>
                </ul>
            </div>

            <div class="intro-div poppins-medium" ref={refIntroduction}>
                <h1 class="intro">Introduction</h1>
                <p class="intro-content">HipHop Fish & Chicken is a <span class="bold-text">fast-food restaurant</span> that specializes in serving customers primarily <span class="bold-text">in Baltimore</span> and other cities across the state of Maryland, USA. Despite offering tasty food and diverse meal plans, HipHop Fish & Chicken's website lacks appeal and frustrates users with its dull design and poor user experience. This <span class="bold-text">project focuses on redesigning the logo and navigation menu pages.</span></p>
            </div>

            <div class="problem-statement-div poppins-medium">
                <h1 class="problem-statement">Problem Statement</h1>
                <p class="problem-statement-content">The <span class="bold-text">current website</span> suffers from low visibility, inadequate affordances, limited features and functionality, poor alignment, and an unattractive visual design and layout. The <span class="bold-text">design principles are not adhered</span> to, resulting in a violation of several key principles.</p>
            </div>

            <div className="hip-hop-div-gradient-bg poppins-medium" ref={refResearch}>
                <div class="ux-research-div">
                    <h1 class="ux-research">UX Research</h1>
                    <div class="ux-research-content-column">
                        <p class="ux-research-content"><span class="bold-text">12 User Interviews</span></p>
                        <p class="ux-research-content"><span class="bold-text">5 Different Competitor's Website Analysis</span></p>
                    </div>
                </div>
            </div>

            <div class="user-insights-div poppins-medium">
                <h1 class="user-insights">User Insights</h1>
                <p class="user-insights-content">
                    By <span class="bold-text">interviewing 12 diverse range of users</span>, including existing customers, frequent online food orderers, and those who have visited competitor restaurants, I gathered valuable feedback and insights. This comprehensive understanding guided the website redesign process to meet the needs and preferences of the target audience. <span class="bold-text">Some pain points that users had</span> are listed below,
                    <ol><span class="bold-text">
                        <li>1. Difficulty navigation</li>
                        <li>2. Complex ordering process</li>
                        <li>3. Limited visual representation</li>
                        <li>4. Unresponsive or inaccessible design</li>
                        <li>5. Lack of clear menu options</li></span>
                    </ol>
                </p>
            </div>

            <div class="swot-analysis-div poppins-medium">
                <h1 class="swot-analysis">S.W.O.T Analysis</h1>
                <p class="swot-analysis-content"><span class="bold-text">By thorough competitor analysis</span>, several strengths, weaknesses, opportunities, and threats were identified.
                </p>
            </div>

            <img class="swot-analysis-img"
                src="/hip-hop-swot.svg"
                onClick={() => expandImage("/hip-hop-swot.svg")}
            />

            {expandedImage && (
                <div className="overlay" onClick={closeImage}>
                    <span className="close-btn" onClick={closeImage}>
                        &times;
                    </span>
                    <img src={expandedImage} id="expandedImage" alt="Expanded" />
                </div>
            )}

            <div class="user-personas-div poppins-medium">
                <h1 class="user-personas">User Personas</h1>
                <p class="user-personas-content"><span class="bold-text">3 user personas</span> were developed based on the insights gathered from user interviews and research. These personas <span class="bold-text">represent archetypal users and help create a deeper understanding of the target audience</span>, their goals, needs, behaviors, and preferences. These user personas serve as a valuable reference throughout the design process, ensuring that the redesigned website caters to the specific needs of different user types.
                </p>
            </div>

            <div className="user-persona-images-div">
                <img
                    src="/hip-hop-persona-1.svg"
                    onClick={() => expandImage("/hip-hop-persona-1.svg")}
                />
                <img
                    src="/hip-hop-persona-2.svg"
                    onClick={() => expandImage("/hip-hop-persona-2.svg")}
                />
                <img
                    src="/hip-hop-persona-3.svg"
                    onClick={() => expandImage("/hip-hop-persona-3.svg")}
                />
            </div>

            <div className="hip-hop-div-gradient-bg poppins-medium" ref={refIdeation}>
                <div class="ux-research-div">
                    <h1 class="ux-research">Ideation</h1>
                </div>
            </div>

            <div class="site-map-div poppins-medium">
                <h1 class="site-map">Site Map</h1>
                <p class="site-map-content">During the exploration and ideation phase a new sitemap was designed. I have <span class="bold-text">included many new pages and features</span>. The About page would give a detailed idea about what the food chain is and its history. The menu page has the functionality of listing & ordering food as well. The locations page has the google map and also lists the places that are closer to the current location.
                </p>
            </div>
            <img class="mind-map-img"
                src="/hip-hop-site-map.svg"
                onClick={() => expandImage("/hip-hop-site-map.svg")}
            />

            <div class="word-dump-div poppins-medium">
                <h1 class="word-dump">Word Dump</h1>
                <p class="word-dump-content">The top three categories of words that emerged were Chicken, Fish, and Varieties. These <span class="bold-text">words were generated after considering the unique selling point of the restaurant</span>, which specializes in signature fish and chicken dishes. The identified end users showed a significant interest in these offerings, particularly the combination of fish and chicken, which served as a major attraction.
                </p>
            </div>
            <div class="word-dump-content-column poppins-medium">
                <p><span class="bold-text">Chicken   </span>🐔
                    <ol>
                        <li>Spicy</li>
                        <li>Fried</li>
                        <li>Juicy</li>
                        <li>Yummy</li>
                        <li>Fiery</li>
                        <li>Tender</li>
                        <li>Delicious</li>
                        <li>Crispy</li>
                    </ol>
                </p>
                <p><span class="bold-text">Fish   </span>🐟
                    <ol>
                        <li>Seafood</li>
                        <li>Continental</li>
                        <li>Mild</li>
                        <li>Salty</li>
                        <li>Fried</li>
                        <li>Delicate</li>
                        <li>Tasty</li>
                    </ol>
                </p>
                <p><span class="bold-text">Varieties   </span>🍱
                    <ol>
                        <li>Hot Chicken</li>
                        <li>Fiery Chicken</li>
                        <li>Buffalo Chicken Wings</li>
                        <li>Chicken Leg Piece</li>
                        <li>Chicken Nuggets</li>
                        <li>Fish Fry</li>
                        <li>Ocean Fish</li>
                    </ol>
                </p>
            </div>

            <div class="iconization-div poppins-medium">
                <h1 class="iconization">Iconization</h1>
                <p class="iconization-content">Different sets of icons was discovered. Especially sets of Chicken and Fish icons was hand drawn initially to arrive at a design concept. The <span class="bold-text">chicken head with the comb and the fish body with the caudal fin was finalized to emphasize more on the brand identity.</span>
                </p>
            </div>
            <img class="iconization-img"
                src="/hip-hop-iconization.svg"
                onClick={() => expandImage("/hip-hop-iconization.svg")}
            />

            <div class="moodboard-div poppins-medium">
                <div class="moodboard-column">
                    <h1 class="moodboard">Moodboard</h1>
                    <p class="moodboard-content">The moodboard for the HipHop Fish & Chicken redesign project includes pictures of chicken and fish dishes, showcasing their delicious and colorful varieties such as chicken leg pieces, fiery chicken, crispy chicken, and fish fingers. The images are visually appealing, with <span class="bold-text">a dominant red color scheme that represents spice, energy, and strength.</span> The inclusion of an aquatic picture highlights the restaurant's strength in serving fish and seafood. Overall, the moodboard captures the enticing and flavorful nature of the food, attracting people's cravings for spicy dishes.
                    </p>
                </div>
                <img class="hip-hop-moodboard-img"
                    src="/hip-hop-moodboard.svg"
                    onClick={() => expandImage("/hip-hop-moodboard.svg")}
                />
            </div>

            <div className="hip-hop-div-gradient-bg poppins-medium" ref={refIdeation}>
                <div class="ux-research-div">
                    <h1 class="ux-research">Logo Design</h1>
                </div>
            </div>

            <img class="moodboard-img"
                src="/hip-hop-logo-design.svg"
                onClick={() => expandImage("/hip-hop-logo-design.svg")}
            />

            <div className="hip-hop-div-gradient-bg poppins-medium">
                <div class="ux-research-div">
                    <h1 class="ux-research">Low-Fidelity Prototypes</h1>
                </div>
            </div>

            <div class="moodboard-div poppins-medium">
                <div class="moodboard-column">
                    <p class="moodboard-content">After designing the logo, the next step was to design low-fidelity prototypes. This involved <span class="bold-text">hand-drawn paper prototypes</span> and digital wireframes, which were then translated into a digital version. The design process <span class="bold-text">considered both the business needs and user needs</span>, aiming to address user pain points and create an enjoyable overall experience.
                    </p>
                </div>
                <img class="moodboard-img"
                    src="/hip-hop-lofi-gallery.svg"
                    onClick={() => expandImage("/hip-hop-lofi-gallery.svg")}
                />
            </div>

            <div className="hi-fi-images-div">
                <img class="hi-fi-img"
                    src="/hip-hop-lofi-home.svg"
                    onClick={() => expandImage("/hip-hop-lofi-home.svg")}
                />
                <img class="hi-fi-img"
                    src="/hip-hop-lofi-about.svg"
                    onClick={() => expandImage("/hip-hop-lofi-about.svg")}
                />
                <img class="hi-fi-img"
                    src="/hip-hop-lofi-menu.svg"
                    onClick={() => expandImage("/hip-hop-lofi-menu.svg")}
                />
                <img class="hi-fi-img"
                    src="/hip-hop-lofi-location.svg"
                    onClick={() => expandImage("/hip-hop-lofi-location.svg")}
                />
            </div>

            <div className="hip-hop-div-gradient-bg poppins-medium" ref={refHighFidelity}>
                <div class="ux-research-div">
                    <h1 class="ux-research">High-Fidelity Prototypes</h1>
                </div>
            </div>

            <div class="moodboard-div poppins-medium">
                <div class="moodboard-column">
                    <p class="moodboard-content">After creating and performing <span class="bold-text">3 rounds of usability testing</span> with low-fidelity prototypes, the design process progressed to developing high-fidelity prototypes. These prototypes involved <span class="bold-text">refining the design with more detailed and polished visual elements.</span>
                    </p>
                </div>
                <img class="moodboard-img"
                    src="/hip-hop-hifi-gallery.svg"
                    onClick={() => expandImage("/hip-hop-hifi-gallery.svg")}
                />
            </div>

            <div className="hi-fi-images-div">
                <img class="hi-fi-img"
                    src="/hip-hop-hifi-home.svg"
                    onClick={() => expandImage("/hip-hop-hifi-home.svg")}
                />
                <img class="hi-fi-img"
                    src="/hip-hop-hifi-about.svg"
                    onClick={() => expandImage("/hip-hop-hifi-about.svg")}
                />
                <img class="hi-fi-img"
                    src="/hip-hop-hifi-menu.svg"
                    onClick={() => expandImage("/hip-hop-hifi-menu.svg")}
                />
                <img class="hi-fi-img"
                    src="/hip-hop-hifi-location.svg"
                    onClick={() => expandImage("/hip-hop-hifi-location.svg")}
                />
            </div>

            <div className="hip-hop-div-gradient-bg poppins-medium" ref={refConclusion}>
                <div class="ux-research-div">
                    <h1 class="ux-research">Conclusion</h1>
                </div>
            </div>

            <div class="hip-hop-conclusion-div poppins-medium">
                <p class="hip-hop-conclusion-content">
                    <ol>
                        <li><span class="bold-text">Effective navigation</span> - The site’s navigation has been improved by adding new navigational elements like the About page.</li>
                        <li><span class="bold-text">Consistent design</span> - The site has consistent design and aligned well with respect each section and elements.</li>
                        <li><span class="bold-text">Discoverable elements</span> - All the elements and functionality is visible and discoverable without confusing the user.</li>
                        <li><span class="bold-text">Visually appealing UI</span> - The User Interface is redesigned with appealing colors and high definition images.</li>
                        <li><span class="bold-text">Efficient</span> - The website is user friendly and the tasks like ordering of food is achieved with minimal clicks.</li>
                    </ol>
                </p>
            </div>
            <span class="relative flex justify-center">
                <div
                    class="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"
                ></div>

                <span class="relative z-10 bg-white px-6 poppins-medium">Thank You</span>
            </span>
            <div>
                <ProjectsSlider />
            </div>

        </div>



    );
}