// import React, { useEffect, useState } from "react";
// import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
// import AppRouter from "./components/AppRouter";
// import "./App.css";
// import { ArrowRightIcon } from "@heroicons/react/solid";

// export default function App() {
//   const [showPopup, setShowPopup] = useState(false);





//   useEffect(() => {
//     const hasPopupShown = sessionStorage.getItem("hasPopupShown");

//     if (!hasPopupShown) {
//       const timeout = setTimeout(() => {
//         setShowPopup(true);
//         sessionStorage.setItem("hasPopupShown", "true");
//       }, 10000); 

//       return () => clearTimeout(timeout);
//     }
//   }, []);

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   return (
//     <main>
//       <Navbar />
//       <AppRouter />
//       <Footer />
//       {showPopup && (
//         <div className="popup">
//           <div className="popup-content">
//             <button onClick={handleClosePopup}>Close</button>

//             <p>This is a pop-up!</p>
//           </div>
//         </div>
//       )}
//     </main>
//   );
// }

import React, { useEffect, useState } from "react";
import Footer from "./components/Footer";
import AppRouter from "./components/AppRouter";
import "./App.css";
import { ArrowRightIcon } from "@heroicons/react/solid";

export default function App() {
  const [showNavbar, setShowNavbar] = useState(true); // State to track whether to show Navbar

  // Function to update showNavbar state based on screen width
  const handleScreenResize = () => {
    setShowNavbar(window.innerWidth <= 767);
  };

  // Effect to update showNavbar state on component mount and screen resize
  useEffect(() => {
    handleScreenResize(); // Check initial screen width
    window.addEventListener("resize", handleScreenResize); // Add event listener for screen resize
    return () => {
      window.removeEventListener("resize", handleScreenResize); // Cleanup on component unmount
    };
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const hasPopupShown = sessionStorage.getItem("hasPopupShown");

    if (!hasPopupShown) {
      const timeout = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem("hasPopupShown", "true");
      }, 10000); // 10 seconds

      return () => clearTimeout(timeout);
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <main>
      {/* {showNavbar && <Navbar />}  */}
      <AppRouter />
      <Footer />
      {/* {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <button onClick={handleClosePopup}>Close</button>
            
            <p>This is a pop-up!</p>
          </div>
        </div>
      )} */}
    </main>
  );
}



// import React, { useEffect, useState } from "react";
// import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
// import AppRouter from "./components/AppRouter";
// import Loading from "./components/Loading"; // Import the Loading component

// export default function App() {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Simulate data loading delay
//     const timeout = setTimeout(() => {
//       setLoading(false);
//     }, 2500);

//     return () => clearTimeout(timeout);
//   }, []);

//   return (
//     <main className="bg-black">
//       {loading ? (
//         <Loading />
//       ) : (
//         <>
//           <Navbar />
//           <AppRouter />
//           <Footer />
//         </>
//       )}
//     </main>
//   );
// }



