import React, { useState, useRef, useEffect } from 'react';
import "./HipHop.css";
import "./IC.css";

export default function IC() {

    const [expandedImage, setExpandedImage] = useState(null);

    const expandImage = (src) => {
        setExpandedImage(src);
    };

    const closeImage = () => {
        setExpandedImage(null);
    };

    const [activePage, setActivePage] = useState("Introduction");

    const refIntroduction = useRef(null);
    const refResearch = useRef(null);
    const refIdeation = useRef(null);
    const refHighFidelity = useRef(null);
    const refConclusion = useRef(null);

    const handleNavigation = (ref, pageName) => {
        setActivePage(pageName);
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const handleScroll = () => {
            const introSection = refIntroduction.current.getBoundingClientRect();
            const isInView = introSection.top >= 0 && introSection.bottom <= window.innerHeight;
            if (isInView) {
                setActivePage("Introduction");
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <div className="HipHop">
            <div className="hiphop-banner-img">
                <img className="header-banner-img"
                    alt="hiphopbanner"
                    src="/deviceframes (7).png"
                />
            </div>

            <div className="column-1">
                <h1 className="project-name">
                    Internationals Connect
                </h1>
                <h2 className="project-type" >
                    UX Case Study
                </h2>
            </div>


            <div className="hiphop_header">
                <div className="column-2">
                    <p className="role">
                        <h3 className="section-heading">Job Roles</h3>
                        <p>
                            <span className="tool">Information Architect</span>
                            <span className="tool">UX Designer</span>
                        </p>
                    </p>
                </div>
                {/* <div className="column-5">
                    <p className="project-duration">
                        <h3 className="section-heading">Project Duration</h3>
                        <p>
                            <span className="tool">3 Months</span>
                        </p>
                    </p>
                </div> */}
                <div className="column-3">
                    <p className="keywords">
                        <h3 className="section-heading">Keywords</h3>
                        <p>
                            <span className="tool">UX Design</span>
                            <span className="tool">Card Sorting</span>
                            <span className="tool">Mind Map</span>
                        </p>
                    </p>
                </div>
                <div className="column-4">
                    <p className="tools-used">
                        <h3 className="section-heading">Tools & Technologies used</h3>
                        <p>
                            <span className="tool">Figma</span>
                            <span className="tool">Miro</span>
                        </p>
                    </p>
                </div>

            </div>

            <div class="breadcrumbs">
                <h3 className="process">Design Process</h3>
                <ul class="ic-steps">
                    <li class="ic-step" onClick={() => handleNavigation(refIntroduction, "Introduction")}>
                        <a>
                            <i aria-hidden="true"></i> Discovery
                        </a>
                    </li>
                    <li class="ic-step" onClick={() => handleNavigation(refResearch)}>
                        <a>
                            <i aria-hidden="true"></i> Research
                        </a>
                    </li>
                    <li class="ic-step" onClick={() => handleNavigation(refIdeation)}>
                        <a>
                            <i aria-hidden="true"></i> Ideation
                        </a>
                    </li>
                    <li class="ic-step" onClick={() => handleNavigation(refHighFidelity)}>
                        <a>
                            <i aria-hidden="true"></i> High-Fidelity
                        </a>
                    </li>
                    <li class="ic-step" onClick={() => handleNavigation(refConclusion)}>
                        <a>
                            <i aria-hidden="true"></i> Conclusion
                        </a>
                    </li>
                </ul>
            </div>

            <div class="intro-div" ref={refIntroduction}>
                <h1 class="intro">Introduction</h1>
                <p class="intro-content"><span class="bold-text">Internationals Connect</span> is an imaginary web application designed to <span class="bold-text">assist individuals relocating to foreign countries in finding suitable accommodations, compatible roommates, and engaging communities.</span> This platform aims to alleviate the challenges faced by people moving abroad by facilitating seamless connections and enabling them to find exactly what they require. By providing a comprehensive solution, Internationals Connect streamlines the process and ensures a successful match between users and their specific needs in a new country.</p>
            </div>

            <div class="problem-statement-div">
                <h1 class="problem-statement">Problem Statement</h1>
                <p class="problem-statement-content">Moving to a new country can be an
                    exciting adventure, but it can also be overwhelming, especially when it comes to finding a place to live and connecting with your own community. <span class="bold-text">Many internationals struggle with understanding the housing market in a foreign country, and finding roommates can be a daunting task.</span> Even with the help of online resources like Google Maps, it can be tough to navigate a new city and get a sense of its various neighborhoods. That's where our application comes in! Our goal is to provide a comprehensive guide to a city and country, while also helping users find their perfect living
                    situation, whether it's renting, buying, or finding compatible roommates.</p>
            </div>

            <div className="div-gradient-bg" ref={refResearch}>
                <div class="ux-research-div">
                    <h1 class="ux-research">UX Research</h1>
                    <div class="ux-research-content-column">
                        <p class="ux-research-content"><span class="bold-text">18</span> User Interviews</p>
                        <p class="ux-research-content"><span class="bold-text">7 </span> Open card sorting sessions</p>
                        <p class="ux-research-content"><span class="bold-text">9 </span> Different Competitor's Website Analysis</p>
                    </div>
                </div>
            </div>

            <div class="user-insights-div">
                <h1 class="user-insights">User Insights</h1>
                <p class="user-insights-content">
                    After conducting interviews with 18 users who
                    were planning to move abroad for various reasons
                    such as higher studies, work, and business, it was revealed that many users were unaware of
                    existing applications that could assist them in finding accommodation and
                    roommates abroad. Among those who were aware of such applications,
                    several encountered challenges. These <span class="bold-text">challenges included </span>difficulties in<ol><span class="bold-text">
                        <li>1. Understanding the rental process</li>
                        <li>2. Finding compatible roommates</li>
                        <li>3. Cognitive overload due to excessive information</li>
                        <li>4. Complex design, and unwanted features</li></span>
                    </ol>
                </p>
            </div>

            <div class="user-personas-div">
                <h1 class="user-personas">User Personas</h1>
                <p class="user-personas-content">Based on the information obtained from user
                    interviews and research, three user personas were created. These personas
                    are fictional representations of typical users and provide valuable insights
                    into the target audience's characteristics, goals, needs, behaviors, and preferences.
                </p>
            </div>

            <div className="user-persona-images-div">
                <img
                    src="/User Personas 1 (1).svg"
                    onClick={() => expandImage("/User Personas 1 (1).svg")}
                />
                <img
                    src="/User Personas 2 (1).svg"
                    onClick={() => expandImage("/User Personas 2 (1).svg")}
                />
                <img
                    src="/User Personas 3 (1).svg"
                    onClick={() => expandImage("/User Personas 3 (1).svg")}
                />
            </div>

            {expandedImage && (
                <div className="overlay" onClick={closeImage}>
                    <span className="close-btn" onClick={closeImage}>
                        &times;
                    </span>
                    <img src={expandedImage} id="expandedImage" alt="Expanded" />
                </div>
            )}

            <div class="card-sorting-div">
                <h1 class="card-sorting">Card Sorting</h1>
                <p class="card-sorting-content">Card sorting technique was utilized in the Internationals Connect project.
                    The objective was to enhance the app's information architecture and navigation to better support expats in finding relevant resources
                    and connecting with international communities. Through <span class="bold-text">open card sorting sessions</span> with diverse participants,
                    the app's content and features were organized into intuitive categories based on user preferences.
                    The collected data provided <span class="bold-text">valuable insights</span>, enabling the design team to create a <span class="bold-text">user-centric
                        and user-friendly structure</span> for Internationals Connect, facilitating seamless expat experiences and fostering global connections.
                </p>
            </div>

            <div className="card-sorting-images-div">
                <img
                    src="/card-sorting-img-1.svg"
                    onClick={() => expandImage("/card-sorting-img-1.svg")}
                />
                <img
                    src="/card-sorting-img-2.svg"
                    onClick={() => expandImage("/card-sorting-img-2.svg")}
                />
            </div>

            <div className="div-gradient-bg" ref={refIdeation}>
                <div class="ux-research-div">
                    <h1 class="ux-research">Ideation</h1>
                </div>
            </div>

            <div class="mind-map-div">
                <h1 class="mind-map">Mind Map</h1>
                <p class="mind-map-content">I created a mind map to <span class="bold-text">explore the navigation and layout options</span> for the Internationals Connect application.
                    I generated a variety of ideas and ultimately determined that it
                    would be best to provide a <span class="bold-text">user-friendly experience</span> by allowing users to access key features <span class="bold-text">without requiring
                        them to log in or sign up</span> right away.
                </p>
            </div>
            <img class="mind-map-img"
                src="/mindmap.jpg"
                onClick={() => expandImage("/mindmap.jpg")}
            />

            <div class="moodboard-div">
                <div class="moodboard-column">
                    <h1 class="moodboard">Moodboard</h1>
                    <p class="moodboard-content">Later I started designing the moodboard, that's like a colorful travel brochure
                        for ambitious minds! It features houses in whimsical shades of <span class="bold-text">#69ACB4, #DDD876, #898582,</span> and<span class="bold-text"> #EFECEC,</span> enticing you to embark on an adventure abroad. It's like a kaleidoscope of knowledge, where
                        expats embrace new cultures and never run out of 'study' puns!
                    </p>
                </div>
                <img class="moodboard-img"
                    src="/IC-moodboard.gif"
                    onClick={() => expandImage("/IC-moodboard.gif")}
                />
            </div>

            <div className="div-gradient-bg" ref={refHighFidelity}>
                <div class="ux-research-div">
                    <h1 class="ux-research">High-Fidelity Prototypes</h1>
                </div>
            </div>

            <div class="moodboard-div">
                <div class="moodboard-column">
                    <p class="moodboard-content">In the High-Fidelity phase,
                        I began the design process by creating the landing page and sub
                        navigation pages such as Home, Housing, and Roommates. Using Figma,
                        I designed mockups of the pages and created <span class="bold-text">interactive prototypes</span> to simulate user interactions. I <span class="bold-text">iterated </span> on the designs based on
                        user feedback, making approximately <span class="bold-text">five rounds</span> of improvements to
                        ensure a user-friendly experience.
                    </p>
                </div>
                <img class="moodboard-img"
                    src="/Rent Screen.png"
                    onClick={() => expandImage("/Rent Screen.png")}
                />
            </div>

            <div className="hi-fi-images-div">
                <img class="hi-fi-img"
                    src="/Landing Page (3).png"
                    onClick={() => expandImage("/Landing Page (3).png")}
                />
                <img class="hi-fi-img"
                    src="/Home Screen (3).png"
                    onClick={() => expandImage("/Home Screen (3).png")}
                />
                <img class="hi-fi-img"
                    src="/Housing Screen (3).png"
                    onClick={() => expandImage("/Housing Screen (3).png")}
                />
                <img class="hi-fi-img"
                    src="/Roommates Screen (3).png"
                    onClick={() => expandImage("/Roommates Screen (3).png")}
                />
            </div>

            <div class="housing-div">
                <div class="moodboard-column">
                    <h1 class="moodboard">Housing Page</h1>
                    <p class="moodboard-content">During the design of the Housing Screen page,
                        a significant improvement was made by adding a <span class="bold-text">dropdown</span> to the number of <span class="bold-text">bedroom selection</span> in the housing search result cards. This allowed users to quickly view the prices of different houses
                        based on the number of bedrooms in a specific apartment, eliminating the need for additional clicks. This achieved a <span class="bold-text">91% Click-through Rate (CTR).</span>
                    </p>

                </div>
                <img class="moodboard-img"
                    src="/housing.gif"
                    onClick={() => expandImage("/housing.gif")}
                />
            </div>

            <div class="housing-div">
                <div class="moodboard-column">
                    <h1 class="moodboard">Roommates Page</h1>
                    <p class="moodboard-content">Another significant addition to the design was the inclusion of a comprehensive <span class="bold-text">price filter that supports all currencies worldwide.</span> This allows international users to easily view and compare prices in their native currency. Additionally, the housing and roommates screens feature a prominent FAQ link, providing access to a dedicated page that addresses common housing-related questions and policies,
                        ensuring that internationals have access to relevant information. This achieved a <span class="bold-text">100% Click-through Rate (CTR).</span>
                    </p>
                </div>
                <img class="moodboard-img"
                    src="/roommates.gif"
                    onClick={() => expandImage("/roommates.gif")}
                />
            </div>

            <div className="div-gradient-bg" ref={refConclusion}>
                <div class="ux-research-div">
                    <h1 class="ux-research">Conclusion</h1>
                </div>
            </div>

            <div class="conclusion-div">
                <div class="moodboard-column">
                    <p class="moodboard-content">The application's navigation is streamlined, emphasizing key sections such as About the city, Housing, Roommates, and Events, resulting in a clean and efficient user interface design. This achieved a <span class="bold-text">clear, uncluttered visuals that
                        minimize cognitive overload and incorporates effective design constraints for improved usability.</span> Furthermore, user feedback surveys reported a <span class="bold-text">80% increase in user satisfaction</span> compared to using other applications, indicating a positive reception of the streamlined design approach.
                    </p>
                </div>
                <img class="moodboard-img"
                    src="/IC-conclusion.gif"
                    onClick={() => expandImage("/IC-conclusion.gif")}
                />
            </div>
        </div >

    );
}