import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Projects from "./Projects";
import Skills from "./Skills";
import Hire from "./Hire";
import Loading from "./Loading";
import ProjectsSlider from "./ProjectsSlider";
import WhyMe from "./WhyMe";
import Testimonials from "./Testimonials";
import { ArrowRightIcon } from "@heroicons/react/solid";
import "./Home.css";
import "./Margins.css";
import Nav from "./Nav";

export default function Home() {
    const homeRef = useRef(null);
    const projectsRef = useRef(null);
    const hireRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [isInView, setIsInView] = useState(false);

    const scrollToProjects = () => {
        console.log("Scrolling to Projects section");
        const projectsSection = document.getElementById('projects');
        if (projectsSection) {
            window.scrollTo({
                top: projectsSection.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const scrollToHire = () => {
        console.log("Scrolling to Hire section");
        hireRef.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const handleScroll = () => {
            const homeElement = homeRef.current;
            const windowHeight = window.innerHeight;
            if (homeElement && !isInView && homeElement.getBoundingClientRect().top <= windowHeight) {
                setIsInView(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isInView]);

    useEffect(() => {
        // Simulate data loading delay
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div>
            <a href="#main-content" class="visually-hidden focusable">Skip to main content</a>
            <a href="#menu" class="visually-hidden focusable">Skip to menu</a>
            <Helmet>
                <title>Shyam Portfolio - UX Case Studies</title>
                <meta charset="UTF-8"></meta>
                <meta name="description" content="Explore UX Design projects and UX case studies by Shyam"></meta>
                <meta name="keywords" content="UX, UX projects, UI projects, UI/UX case studies, Figma, Adobe XD, design, user research, user experience, user experience designing, UI/UX, shyam UI/UX, shyam ui/ux, shyam ravichandran, shyam, shyam ravi, portfolio, shyam portfolio, shyam design portfolio, ui development, shyam ux, shyam"></meta>
            </Helmet>
            <nav id="menu">
                <Nav />
            </nav>

            <div ref={homeRef} className={`flex h-screen items-center justify-center bg-black p-5 mb-20 poppins-medium ${isInView ? 'fade-in' : ''}`}>


                <div class="max-w-screen-xl mx-auto px-6 md:px-20">
                    <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-10 py-12 sm:py-16 lg:py-24">
                        <div className="hidden md:flex justify-center md:justify-center md:order-2 lg:justify-self-end">

                            <img src="./my-pic.png" alt="Shyam picture" className="w-auto h-auto md:w-96 md:h-96 rounded-full mx-auto md:mx-0" />


                        </div>


                        <div class="md:flex md:flex-col md:order-1">
                            <h1 className="max-w-4xl font-display text-5xl font-bold tracking-normal text-white sm:text-7xl mt-2 text-left">
                                Hi, I'm
                                <span className="relative whitespace-nowrap text-purple-900">
                                    <span>  </span>
                                    <mark className="px-2 text-white bg-shyam rounded">Shyam</mark>
                                </span>
                            </h1>

                            <p class="mt-20 mb-6 text-white">With 3+ years of industry experience in UX/UI roles at CenturyLink Technologies, and an M.S in Human-Centered Computing from UMBC, I'm fueled by skills and passion for crafting user-friendly and immersive experiences by merging creativity with technical expertise.</p>
                            <div class="flex justify-start items-center space-x-5 text-white">
                                <div class="bg-shyam px-3 py-1 rounded-md inline-flex items-center">
                                    <a onClick={scrollToProjects} class="flex items-center underline cursor-pointer" role="button">
                                        View My Projects
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v9.293l3.646-3.647a1 1 0 111.415 1.415l-5 5a1 1 0 01-1.415 0l-5-5a1 1 0 111.415-1.415L9 13.293V4a1 1 0 011-1z" clip-rule="evenodd" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <main id="main-content">
                <div id="projects" ref={projectsRef}>
                    <Projects />
                </div>
            </main>

            <div>
                <WhyMe />
            </div>
            <div>
                <Testimonials />
            </div>
            <div ref={hireRef}>
                <Hire />
            </div>
        </div>
    );
}
