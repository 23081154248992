import React, { useState, useRef, useEffect } from 'react';
import "./HipHop.css";
import "./Costco.css";
import ProjectsSlider from './ProjectsSlider';
import Nav from "./Nav";

export default function Costco() {

    const [expandedImage, setExpandedImage] = useState(null);

    const expandImage = (src) => {
        setExpandedImage(src);
    };

    const closeImage = () => {
        setExpandedImage(null);
    };

    const [activePage, setActivePage] = useState("Introduction");

    const refIntroduction = useRef(null);
    const refResearch = useRef(null);
    const refHighFidelity = useRef(null);
    const refConclusion = useRef(null);

    const handleNavigation = (ref, pageName) => {
        setActivePage(pageName);
        ref.current.scrollIntoView({ behavior: "smooth" });
    };



    const [isInView, setIsInView] = useState(false);
    const aboutRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const aboutElement = aboutRef.current;
            const windowHeight = window.innerHeight;
            if (aboutElement && !isInView && aboutElement.getBoundingClientRect().top <= windowHeight) {
                setIsInView(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isInView]);

    return (

        <div className="HipHop">
            <Nav />
            <div className="hiphop-banner-img">
                <img className="header-banner-img"
                    alt="hiphopbanner"
                    src="/costco-header-banner.png"
                />
            </div>

            <div className="column-1 poppins-medium">
                <h1 className="project-name">
                    Costco Mobile Application Redesign
                </h1>
                <h2 className="project-type" >
                    UX Case Study
                </h2>
            </div>

            <div className="hiphop_header poppins-medium">
                <div className="column-2">
                    <p className="role">
                        <h3 className="section-heading">Job Roles</h3>
                        <p>
                            <span className="tool">UX Researcher</span>
                            <span className="tool">UX Designer</span>
                        </p>
                    </p>
                </div>
                {/* <div className="column-5">
                    <p className="project-duration">
                        <h3 className="section-heading">Project Duration</h3>
                        <p>
                            <span className="tool">4 Months</span>
                        </p>
                    </p>
                </div> */}
                <div className="column-3">
                    <p className="keywords">
                        <h3 className="section-heading">Keywords</h3>
                        <p>
                            <span className="tool">User Interviews</span>
                            <span className="tool">Heuristic Evaluation</span>
                        </p>
                    </p>
                </div>
                <div className="column-4">
                    <p className="tools-used">
                        <h3 className="section-heading">Tools & Technologies used</h3>
                        <p>
                            <span className="tool">Adobe XD</span>
                            <span className="tool">Heurix</span>
                        </p>
                    </p>
                </div>

            </div>

            <div class="breadcrumbs poppins-medium">
                <h3 className="process">Design Process</h3>
                <ul class="costco-steps">
                    <li class={`costco-step ${activePage === "Introduction" ? "active" : ""}`} onClick={() => handleNavigation(refIntroduction, "Introduction")}>
                        <a>
                            <i aria-hidden="true"></i> Discovery
                        </a>
                    </li>
                    <li class="costco-step" onClick={() => handleNavigation(refResearch)}>
                        <a>
                            <i aria-hidden="true"></i> Research
                        </a>
                    </li>
                    <li class="costco-step" onClick={() => handleNavigation(refHighFidelity)}>
                        <a>
                            <i aria-hidden="true"></i> High-Fidelity
                        </a>
                    </li>
                    <li class="costco-step" onClick={() => handleNavigation(refConclusion)}>
                        <a>
                            <i aria-hidden="true"></i> Conclusion
                        </a>
                    </li>
                </ul>
            </div>

            <div class="intro-div poppins-medium" ref={refIntroduction}>
                <h1 class="intro">Introduction</h1>
                <p class="intro-content">Costco Wholesale is an American multinational corporation that operates a chain of <span class="bold-text">membership-only big-box retail stores.</span> Being one of the top American multinational companies with over 800 warehouses, a huge gap was discovered between the customer’s experience of the in-store and digital space. This was a big problem as online grocery shopping increased by 45% during the pandemic. In this project, I <span class="bold-text">worked on improving the design of a few pages in the Costco mobile application.</span></p>
            </div>

            <div class="problem-statement-div poppins-medium">
                <h1 class="problem-statement">Problem Statement</h1>
                <p class="problem-statement-content">
                    The <span class="bold-text">current user experience (UX) design</span> of Costco's mobile application <span class="bold-text">presents several challenges</span>, hindering optimal user engagement and satisfaction. <span class="bold-text">Users struggle to easily navigate the app, locate desired products efficiently, and manage their shopping experiences effectively</span>. Furthermore, the existing design lacks intuitive features, leading to a disjointed and frustrating user journey. These issues call for a comprehensive UX redesign to enhance usability, streamline product discovery, and create a seamless and enjoyable shopping experience for Costco's mobile app users.</p>
            </div>

            <div className="costco-div-gradient-bg poppins-medium" ref={refResearch}>
                <div class="ux-research-div">
                    <h1 class="ux-research">UX Research</h1>
                    <div class="ux-research-content-column">
                        <p class="ux-research-content"><span class="bold-text">8 Exploratory Interviews</span></p>
                        <p class="ux-research-content"><span class="bold-text">12 Task-Based Interviews</span></p>
                    </div>
                </div>
            </div>

            <div class="user-insights-div poppins-medium">
                <h1 class="user-insights">Exploratory Interviews</h1>
                <p class="user-insights-content">
                    Exploratory Interviews were <span class="bold-text">conducted via Zoom.</span>  This helped me understand the issue with the application more deeply. I asked them about their expectations, requirements, and overall experience. Few Interview questions,
                    <ol>
                        <li>1. What is your age?</li>
                        <li>2. How often do you use Costco Application?</li>
                        <li>3. What do you usually order?</li>
                        <li>4. What other sites do you use?</li>
                        <li>5. Do you prefer online or in-store?</li>
                        <li>6. Do you usually shop sales and offer?</li>
                        <li>7. Do you find it easy to get a product that you want?</li>
                    </ol>
                </p>
            </div>

            <div class="user-insights-div poppins-medium">
                <h1 class="user-insights">User Insights</h1>
                <p class="user-insights-content">
                    By <span class="bold-text">interviewing 12 diverse range of users</span>, including existing customers, frequent online food orderers, and those who have visited competitor restaurants, I gathered valuable feedback and insights. This comprehensive understanding guided the website redesign process to meet the needs and preferences of the target audience. <span class="bold-text">Some pain points that users had</span> are listed below,
                    <ol>
                        <li>Surprisingly <span class="bold-text">7</span> out of 8 <span class="bold-text">participants did not prefer to buy anything through the Costco mobile application</span></li>
                        <li>All <span class="bold-text">8 participants preferred to use Instacart</span> to order groceries from Costco</li>
                        <li>All 8 participants had a lot of similar user frustrations like,</li>
                        <li>- Readability and Navigability issues</li>
                        <li>- Issues with Discoverability</li>
                    </ol>
                </p>
            </div>

            <div class="user-insights-div poppins-medium">
                <h1 class="user-insights">Task-Based Interviews</h1>
                <p class="user-insights-content">
                    A task-based evaluation was performed and <span class="bold-text">5 participants were closely observed.</span> The task was to search for a laptop and add it to the cart. I closely watched their interaction with the application. All 5 users were finding it challenging to accomplish this simple task. More problems that the users had included,
                    <ol>
                        <li>1. The <span class="bold-text">non-member had no way of knowing if the item is for members only or not</span> without opening up the product detail page.</li>
                        <li>2. The <span class="bold-text">homepage starts below the fold</span> as the top section is filled with unimportant information.</li>
                        <li>3. If the user selected a product from the home screen and entered the product page and two other pages further, the home button was not taking the user back to the home screen, instead it retraced the entire user journey.</li>
                        <li>4. The <span class="bold-text">images and screen layout are not consistent</span> which makes the interface look inconsistent and awkward.</li>
                    </ol>
                </p>
            </div>

            <div className="costco-div-gradient-bg poppins-medium">
                <div class="ux-research-div">
                    <h1 class="ux-research">Low-Fidelity Prototypes</h1>
                </div>
            </div>

            <div class="costco-lofi-hifi-div poppins-medium">
                <p class="costco-lofi-content">Next, I started finding creative solutions to solve the identified user pain points. This involved <span class="bold-text">hand-drawn paper prototypes and wireframes,</span> which were then translated into a digital version. The pages that I planned to redesign were,
                    <ol>
                        <li>1. Landing Page</li>
                        <li>2. Product Search Result Page</li>
                        <li>3. Product Details Page</li>
                    </ol>
                    My main goal was to understand the user's expectations and redesign according to them. To start with, I used a pen and paper to sketch out simple designs. I sat down and listed all the common features that the user needs that I had collected from the user research. I made the first batch of wireframes for the three screens. I then did <span class="bold-text">rounds of iterations with the design by performing usability testing.</span>
                </p>
            </div>


            {
                expandedImage && (
                    <div className="overlay" onClick={closeImage}>
                        <span className="close-btn" onClick={closeImage}>
                            &times;
                        </span>
                        <figure><img src={expandedImage} id="expandedImage" alt="Expanded" /></figure>
                    </div>
                )
            }

            <div className="user-persona-images-div">
                <img
                    src="/costco-lofi-1.svg"
                    onClick={() => expandImage("/costco-lofi-1.svg")}
                />
                <img
                    src="/costco-lofi-2.svg"
                    onClick={() => expandImage("/costco-lofi-2.svg")}
                />
                <img
                    src="/costco-lofi-3.svg"
                    onClick={() => expandImage("/costco-lofi-3.svg")}
                />
            </div>

            <div className="costco-div-gradient-bg poppins-medium" ref={refHighFidelity}>
                <div class="ux-research-div">
                    <h1 class="ux-research">High-Fidelity Prototypes</h1>
                </div>
            </div>

            <div class="costco-lofi-hifi-div poppins-medium">
                <p class="costco-hifi-content">The high-fidelity mockups of the Costco mobile application focused on enhancing visual appeal, intuitiveness, and user-friendliness. <span class="bold-text">Metaphorical icons</span> were implemented to represent functions effectively, <span class="bold-text">aligning with user's mental models.</span> Promotional images were moved to high-quality carousel images, and sections like Top Deals and Bestsellers were introduced for <span class="bold-text">product categorization.</span> The store's opening timings were displayed prominently alongside the location, aiding users. <span class="bold-text">A simplified tab indicated if a product was for members only.</span> An 'Add' button was added to streamline item selection, saving users time. Delivery details were made easily accessible, and item quantities were clearly presented for efficient management.
                </p>
            </div>

            <div className="user-persona-images-div">
                <img
                    src="/costco-hifi-1.svg"
                    onClick={() => expandImage("/costco-hifi-1.svg")}
                />
                <img
                    src="/costco-hifi-2.svg"
                    onClick={() => expandImage("/costco-hifi-2.svg")}
                />
                <img
                    src="/costco-hifi-3.svg"
                    onClick={() => expandImage("/costco-hifi-3.svg")}
                />
            </div>

            <div className="costco-div-gradient-bg poppins-medium" ref={refConclusion}>
                <div class="ux-research-div">
                    <h1 class="ux-research">Conclusion</h1>
                </div>
            </div>

            <div class="costco-conclusion-div poppins-medium">
                <p class="costco-conclusion-content">
                    In conclusion, the redesigned work for Costco's mobile application aimed to improve the user interface and enhance user experience. Through the implementation of metaphorical icons, visually appealing carousel images, and prominent display of delivery details, the redesign sought to make the application more intuitive and user-friendly.
                    <span class="bold-text"> Quantitative outcomes</span> from user testing indicate positive improvements in various metrics. There was a staggering <span class="bold-text">90% increase in conversion rates</span> for the "Top Deals" and "Bestsellers" sections, indicating an increased engagement and interest in these sections. The addition of an "Add" button and favorite icon led to <span class="bold-text">85% increase in click-through rates,</span> facilitating quicker item selection and saving user's time.
                </p>
            </div>

            <span class="relative flex justify-center poppins-medium">
                <div
                    class="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"
                ></div>

                <span class="relative z-10 bg-white px-6">Thank You</span>
            </span>



            <div>
                <ProjectsSlider />
            </div>
        </div>

    );
}