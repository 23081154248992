import React, { useRef, useState, useEffect } from "react";
import "./Skills.css";
import "./Home.css";
import { ArrowRightIcon } from "@heroicons/react/solid";

export default function Skills() {
    const [activePage, setActivePage] = useState("");

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <section id="skills">
            <div className="home-column-1">

                <nav id="navbar" className="md:sticky top-20 z-40 mb-20">
                    <nav className="md:ml-4 md:flex flex-wrap items-center justify-center">
                        <div className="logo-menu-container bg-black rounded-full flex items-center px-4 py-2">
                            <a href="/" className="flex items-center">
                                <img
                                    src="./sr-logo-navbar.svg"
                                    alt="Your Logo Alt Text"
                                    className="w-10 h-10 mr-4"
                                />
                                {/* SHYAM RAVICHANDRAN */}
                            </a>
                            <div className="menu-items text-white">
                                <a
                                    href="/about"
                                    className={`mr-5 text-black ${activePage === "about" ? "active" : ""}`}
                                    id="about"
                                    onClick={() => setActivePage("about")}
                                >
                                    About
                                </a>
                                <a
                                    href="/projects"
                                    className={`mr-5 hover:text-black ${activePage === "projects" ? "active" : ""}`}
                                    id="projects"
                                    onClick={() => setActivePage("projects")}
                                >
                                    Projects
                                </a>
                                <a
                                    href="/skills"
                                    className={`mr-5 hover:text-black ${activePage === "skills" ? "active" : ""}`}
                                    id="skills"
                                    onClick={() => setActivePage("skills")}
                                >
                                    Skills
                                </a>
                                <a href="https://drive.google.com/file/d/1irkO9ftjR5L-Oj91rfTVCgf65QHYpFTL/view?usp=drive_link" target="_blank" className="mr-5 hover:text-black" id="resume">
                                    Resume
                                </a>
                                <a
                                    href="/hire"
                                    className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-white mt-4 md:mt-0"
                                >
                                    Hire Me
                                    <ArrowRightIcon className="w-4 h-4 ml-1" />
                                </a>
                            </div>
                        </div>
                    </nav>
                </nav>
            </div>
            <div className="skills">
                <div></div>
                <div>
                    <p className="skills-heading">Skills</p>
                    <img className="skills_image" src="/Skills.svg"></img>
                </div>
            </div>
        </section>
    );
}