import React, { useEffect, useRef, useState } from "react";
import "./Testimonials.css";

export default function Testimonials() {
    const testimonialsRef = useRef(null);
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const testimonialsElement = testimonialsRef.current;
            const windowHeight = window.innerHeight;
            if (testimonialsElement && !isInView && testimonialsElement.getBoundingClientRect().top <= windowHeight) {
                setIsInView(true);
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isInView]);

    return (
        <section className={`bg-white testimonials poppins-medium ${isInView ? 'fade-in' : ''}`}>
            <div className="flex justify-center">
                <div className="max-w-screen-xl mx-auto px-6 md:px-20 py-12 sm:px-6 lg:px-20 lg:py-16" ref={testimonialsRef}>
                    <div className="text-center mt-20">
                        <p className="recent-projects text-sm leading-6 sm:text-base sm:leading-10">Get a glimpse of the praise and feedback shared by my supervisors, mentors, and colleagues</p>
                        <h2 className="mx-auto max-w-4xl font-display text-5xl font-bold tracking-normal text-slate-900 sm:text-7xl mt-2">
                            <span className="relative whitespace-nowrap text-orange-400">
                                <svg aria-hidden="true" viewBox="0 0 418 42" className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none">
                                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                                </svg>
                                <span className="relative fill-orange-300-70">Testimonials</span>
                            </span>
                        </h2>
                    </div>

                    <div className="mt-14 [column-fill:_balance] sm:columns-2 sm:gap-6 lg:columns-3 lg:gap-8">
                        <div className="mb-8 sm:break-inside-avoid">
                            <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                                <div className="flex items-center gap-4">


                                    <div>
                                        <div className="flex justify-center gap-0.5 text-green-500">



                                        </div>

                                        <p className="mt-0.5 text-lg font-medium text-gray-900">Dr. Ravi Kuber</p>
                                        <p className="mt-0.5 text-sm font-small text-gray-600">Associate Professor, UMBC</p>
                                    </div>
                                </div>

                                <p className="mt-4 text-gray-700">
                                    Shyam is an excellent team player, with strong UX research, analytical and design skills. His insights have profoundly impacted our research on understanding older adults videoconferencing adoption.
                                </p>
                            </blockquote>
                        </div>
                        {/* 
                    <div className="mb-8 sm:break-inside-avoid">
                        <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                            <div className="flex items-center gap-4">


                                <div>
                                    <div className="flex justify-center gap-0.5 text-green-500">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                    </div>

                                    <p className="mt-0.5 text-lg font-medium text-gray-900">Paul Starr</p>
                                </div>
                            </div>

                            <p className="mt-4 text-gray-700">
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad mollitia rerum quo unde
                                neque atque molestias quas pariatur! Sint, maxime?
                            </p>
                        </blockquote>
                    </div> */}

                        {/* <div className="mb-8 sm:break-inside-avoid">
                        <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                            <div className="flex items-center gap-4">

                                <div>
                                    <div className="flex justify-center gap-0.5 text-green-500">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                            />
                                        </svg>
                                    </div>

                                    <p className="mt-0.5 text-lg font-medium text-gray-900">Paul Starr</p>
                                </div>
                            </div>

                            <p className="mt-4 text-gray-700">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit esse delectus,
                                maiores fugit, reiciendis culpa inventore sint accusantium libero dolore eos quasi a ex
                                aliquam molestiae. Tenetur hic delectus maxime.
                            </p>
                        </blockquote>
                    </div> */}

                        <div className="mb-8 sm:break-inside-avoid">
                            <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                                <div className="flex items-center gap-4">


                                    <div>
                                        <div className="flex justify-center gap-0.5 text-green-500">

                                        </div>

                                        <p className="mt-0.5 text-lg font-medium text-gray-900">Anup Athreya</p>
                                        <p className="mt-0.5 text-sm font-small text-gray-600">SDE, Lumen Technologies</p>
                                    </div>
                                </div>

                                <p className="mt-4 text-gray-700">
                                    His UI/UX design and development significantly enhanced the user experience of our web applications. His ability to understand complex requirements and translate them into intuitive mockups and prototypes was impressive.
                                </p>

                            </blockquote>
                        </div>

                        <div className="mb-8 sm:break-inside-avoid">
                            <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                                <div className="flex items-center gap-4">


                                    <div>


                                        <p className="mt-0.5 text-lg font-medium text-gray-900">Kamesh Thiru</p>
                                        <p className="mt-0.5 text-sm font-small text-gray-600">Module Lead, Lumen Technologies</p>
                                    </div>
                                </div>

                                <p className="mt-4 text-gray-700">
                                    He demonstrated outstanding proficiency in HTML, CSS, and Nokia SMP platform working on building workflows and custom code implementation.
                                </p>
                            </blockquote>
                        </div>

                        <div className="mb-8 sm:break-inside-avoid">
                            <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                                <div className="flex items-center gap-4">


                                    <div>


                                        <p className="mt-0.5 text-lg font-medium text-gray-900">Bollina Srinivasu</p>
                                        <p className="mt-0.5 text-sm font-small text-gray-600">Technical Lead, Lumen Technologies</p>
                                    </div>
                                </div>

                                <p className="mt-4 text-gray-700">
                                    A flexible team player who can handle design, development, and testing tasks. His work on the SSC dashboard has improved the user experience significantly!
                                </p>
                            </blockquote>
                        </div>

                        {/* <div className="mb-8 sm:break-inside-avoid">
                        <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                            <div className="flex items-center gap-4">


                                <div>


                                    <p className="mt-0.5 text-lg font-medium text-gray-900">Kamesh Thirunavukarasu</p>
                                    <p className="mt-0.5 text-sm font-small text-gray-600">Module Lead, Lumen Technologies</p>
                                </div>
                            </div>

                            <p className="mt-4 text-gray-700">
                                
                            </p>
                        </blockquote>
                    </div> */}

                        <div className="mb-8 sm:break-inside-avoid">
                            <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                                <div className="flex items-center gap-4">


                                    <div>

                                        <p className="mt-0.5 text-lg font-medium text-gray-900">Rajender Prasad</p>
                                        <p className="mt-0.5 text-sm font-small text-gray-600">AI Engineer, Symphony Summit AI</p>
                                    </div>
                                </div>

                                <p className="mt-4 text-gray-700">
                                    Fantastic designer and developer! His expertise in Azure AD Automation transformed our identity management system. The designs were user-centric, and his development skills ensured seamless integration with our existing systems.
                                </p>
                            </blockquote>
                        </div>

                        <div className="mb-8 sm:break-inside-avoid">
                            <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                                <div className="flex items-center gap-4">


                                    <div>
                                        <div className="flex justify-center gap-0.5 text-green-500">

                                        </div>

                                        <p className="mt-0.5 text-lg font-medium text-gray-900">Dr. Anita Komlodi</p>
                                        <p className="mt-0.5 text-sm font-small text-gray-600">Associate Professor, UMBC</p>
                                    </div>
                                </div>

                                <p className="mt-4 text-gray-700">
                                    Shyam demonstrated a strong commitment to HCI principles and a genuine passion for the final project during the UX for AR/VR class.
                                </p>

                            </blockquote>
                        </div>

                        {/* <div className="mb-8 sm:break-inside-avoid">
                        <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                            <div className="flex items-center gap-4">


                                <div>


                                    <p className="mt-0.5 text-lg font-medium text-gray-900">Paul Starr</p>
                                </div>
                            </div>

                            <p className="mt-4 text-gray-700">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam numquam, unde molestiae
                                commodi temporibus dicta.
                            </p>
                        </blockquote>
                    </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
}